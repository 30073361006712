import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Button,Modal,Input} from 'react-bootstrap';
import {isAdmin, isMA} from '../../utils'
import {PATIENT_STATUS_NEW, PATIENT_STATUS_ACTIVE} from '../../constants/other';
import UnenrollPatientPopup from './UnenrollPatientPopup';
import { getValues } from 'redux-form';
import MergePatientForm from './MergePatientForm';
import EditPatientInformationPopup from './EditPatientInformationPopup';
import UpdatePatientMailingAddress from './UpdatePatientMailingAddress';
import AdditionalInformationEmailModal from './AdditionalInformationEmailModal';
import _ from 'lodash';
import moment from 'moment';
import SendToPatientServicesModal from './SendToPatientServicesModal';
import PatientSatisfactionPreferenceModal from './PatientSatisfactionPreferenceModal';
import UpdateContactPreferenceModal from './UpdateContactPreferenceModal';
import ToggleCallSchedulingModal from './ToggleCallSchedulingModal'
import PowerOfAttorneyModal from './PowerOfAttorneyModal';

class Utilities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfirmReset: this.props.showConfirmReset,
            showUnenrollPatientPopup: false,
            showMergePatient: false,
            showEditPatientInfo: false,
            showChangeProvider: false,
	        showAdditionalInformationEmail: false,
            showToggleCallScheduling: false,
            showConfirmSupport: false,
	        showMailingAddress: false,
            newProviderId: null,
	        showPatientSatisfactionPreferences: false,
	        patientSatisfactionPreference: null,
            patientConsentChecked:false,
            showPOAUpdate: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showConfirmReset: nextProps.showConfirmReset || nextProps.app.showConfirmReset,
            formData: nextProps.form
        });
        if ( this.props.selectedPatient && nextProps.selectedPatient && (this.props.selectedPatient.show_nps !== nextProps.selectedPatient.show_nps) ) {
        	this.setState({
		        patientSatisfactionPreference: nextProps.selectedPatient.show_nps,
	        });
        }
    }

    header() {
        return (
            <div style={{height:35}}>
                <div style={{fontSize:18,float:'left'}}>Utilities</div>
            </div>
        )
    }

    handleReset() {
        this.props.showSecondaryTimer( true );
    }

    resetPassword() {
        this.props.showSecondaryTimer( true );
        this.props.adminActions.resetPassword(this.props.selectedPatient);
    }

    handleUnenroll() {
        this.props.showSecondaryTimer();
        this.setState({
            showUnenrollPatientPopup: !this.state.showUnenrollPatientPopup
        }, this.props.adminActions.getUnenrollReasons );
    }

    handleToSupport = () => {
        let { selectedPatient } = this.props;
        if ( selectedPatient && selectedPatient.id ) {
            this.props.adminActions.changeStatusToSupport( selectedPatient.id );
        }
        this.handleConfirmSupport();
    };

    handleConfirmSupport = () => {
        this.props.showSecondaryTimer();
        this.setState({ showConfirmSupport: !this.state.showConfirmSupport });
    };

    handleUpdateContactPreference = () => {
        this.props.showSecondaryTimer();
        this.setState({ showUpdateContactPreference: !this.state.showUpdateContactPreference });
    }

    enableMergePatient() {
        let email = this.props.selectedPatient.email
        let emailDomain = email && email.substr(email.indexOf("@") + 1);
        return emailDomain == 'patient.chartspan.com' ? false : true
    }

    disableResetPassword() {
        const { activeUser } = this.props;
        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag
        return isCne
    }

    toggleMergePatient() {
        this.props.showSecondaryTimer();
        this.setState({
            showMergePatient: !this.state.showMergePatient
        });
    }

    mergePatient() {
        if ( this.state.formData.mergePatientForm ) {
            let mergeData = getValues( this.state.formData.mergePatientForm );
            if ( this.validEmail( mergeData.mergeEmail ) ) {
                this.props.adminActions.mergeAccounts( this.props.selectedPatient.id, mergeData.mergeEmail );
                this.setState({
                    showMergePatient: false
                });
            } else {
                this.setState({
                    emailError: 'Please provide a valid email address'
                });
            }
        } else {
            this.setState({
                emailError: 'Please provide a valid email address'
            });
        }
    }

    validEmail( email ) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    onEditPatientInfoShowHide(){
        this.props.showSecondaryTimer();
        this.setState({
            showEditPatientInfo:!this.state.showEditPatientInfo
        })
    }

	onMailingAddressShowHide(){
		this.props.showSecondaryTimer();
		this.setState({
			showMailingAddress:!this.state.showMailingAddress
		})
	}

    onPOAUpdateShowHide(){
		this.props.showSecondaryTimer();
		this.setState({
			showPOAUpdate:!this.state.showPOAUpdate
		})
	}

	onAdditionalInformationEmailShowHide(){
		this.props.showSecondaryTimer();
		this.setState({
			showAdditionalInformationEmail:!this.state.showAdditionalInformationEmail
		})
	}

	onShoeToggleCallScheduling(){
        this.props.showSecondaryTimer();
        this.setState({
            showToggleCallScheduling:!this.state.showToggleCallScheduling
        })
    }

    toggleChangeProvider() {
        this.props.showSecondaryTimer();
        if(!this.state.showChangeProvider){
            this.props.adminActions.getChangeProviderRequest(this.props.selectedPatient.id,(response)=>{
                console.log(response);
                let requestedProvider = '';
                let requestTime = '';
                if(response.requested_provider_id){
                    const filtredData = _.filter(this.props.patientCcmProviders,(provider)=>{
                       return provider.id ==  response.requested_provider_id;
                    });
                    if(filtredData.length > 0){
                        requestedProvider = filtredData[0].provider;
                        requestTime = moment.utc(response.request_time).local() .format( 'h:mma MM/DD/YYYY' ) ;
                    }
                }
                this.setState({
                    showChangeProvider:true,
                    requestedProvider:requestedProvider,
                    requestTime:requestTime
                });
            });

        }else{
            this.setState({
                showChangeProvider:false,
                requestedProvider:'',
                requestTime:''
            })
        }

    }

	togglePatientSatisfactionPreferences() {
    	this.props.showSecondaryTimer();
		this.setState({
			showPatientSatisfactionPreferences: !this.state.showPatientSatisfactionPreferences,
			patientSatisfactionPreference: this.props.selectedPatient.show_nps,
		});
	}

	onConfirmPatientSatisfactionPreference() {
		if ( this.state.patientSatisfactionPreference !== this.props.selectedPatient.show_nps ) {
		    this.props.adminActions.postPatientSatisfactionPreferences(this.props.selectedPatient.id, this.state.patientSatisfactionPreference);
		}
		this.togglePatientSatisfactionPreferences();
	}

	updatePatientSatisfactionPreference(e) {
    	this.setState({
		    patientSatisfactionPreference: !!Number(e.target.value),
	    })
	};

    changeProvider() {
        if ( !this.state.newProviderId || this.state.newProviderId == this.props.selectedPatient.provider_id ) {
            this.setState({
                providerError: 'Please select a new provider or cancel'
            })
        } else {
            this.props.showSecondaryTimer();
            this.setState({
                providerError: null,
                showChangeProvider: false,
            });
            this.props.adminActions.updatePatientCcmProider( this.props.selectedPatient.id, this.state.newProviderId )
        }
    }

    changeProviderSelect( e ) {
        this.setState({
            newProviderId: e.target.value,
        });
    }

	onConfirmMailingAddressUpdate( address1, address2, city, state, zip_code ) {
		this.props.adminActions.updateAddress(this.props.selectedPatient, address1, address2, city, state, zip_code,
            this.props.selectedPatient.ok_to_text);
		this.onMailingAddressShowHide();
	}

	onConfirmAdditionalInformationEmail( phone_number ) {
		this.props.adminActions.sendAdditionalInformationEmail( this.props.selectedPatient.id, phone_number );
		this.onAdditionalInformationEmailShowHide();
	}

	disableAdditionalInformation() {
		let email = this.props.selectedPatient.email;
		let emailDomain = email && email.substr(email.indexOf("@") + 1);
		return emailDomain === 'patient.chartspan.com' || emailDomain == null
	}

    onPatientConsentReceived(e){
        this.setState({patientConsentChecked:e.target.checked})
    }

    render() {
        let { selectedPatient, userRole,activeUser } = this.props;
        const {patientConsentChecked,newProviderId} = this.state;
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
        let patientId = selectedPatient.id;
        const data = selectedPatient;
        let lastPasswordReset = this.props.lastPasswordReset.change_type != 'UNKNOWN' ? moment.utc(this.props.lastPasswordReset.date_created).local() .format( 'h:mma MM/DD/YYYY' ) : 'N/A';

        let disableUnenroll = !(data.status && (data.status === PATIENT_STATUS_NEW || data.status === PATIENT_STATUS_ACTIVE));
        let disableToSupport = !(data.status && (data.status === PATIENT_STATUS_NEW || data.status === PATIENT_STATUS_ACTIVE));

        const buttonStyle = { display: 'block', marginTop: '7px', marginBottom: '7px' };
        const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '30px' };

        let isUserAdmin = isAdmin(userRole);
        let isMARole = isMA(userRole);

        const filteredData = _.filter(this.props.patientCcmProviders,(provider) => 
        provider.id == newProviderId);
        let selectedProviderName = filteredData.length > 0 ?filteredData[0].provider : '';

        return (
            <Panel header={this.header()}>

                <div className="col-md-6">
                    <h4>Patient Utilities</h4>
                    {
                        !isGAPS &&   <Button bsStyle="primary"
                                             style={buttonStyle}
                                             onClick={this.onEditPatientInfoShowHide.bind(this)}>
                            Update Patient Details
                        </Button>
                    }


	                <Button bsStyle="primary"
	                        style={buttonStyle}
	                        onClick={this.onMailingAddressShowHide.bind(this)}>
		                Update Mailing Address
	                </Button>
                    {
                        !isMARole && <Button bsStyle="primary"
                                style={buttonStyle}
                                onClick={this.onPOAUpdateShowHide.bind(this)}>
                            Power of Attorney
                        </Button>
                    }
                    
                    
                    { isUserAdmin && !isGAPS &&
                        <Button bsStyle="primary"
                                style={buttonStyle}
                                disabled={this.enableMergePatient() || isRPMUser}
                                onClick={this.toggleMergePatient.bind(this)}>
                            Merge Patient Accounts
                        </Button>
                    }
                    {
                        !isGAPS && <Button bsStyle="primary"
                                           style={buttonStyle}
                                           id="updateContactPreference"
                                           onClick={this.handleUpdateContactPreference.bind(this)}>
                            Update Contact Preference
                        </Button>
                    }
                    {
                        !isGAPS && <div><Button bsStyle="primary"
                                           onClick={this.handleReset.bind(this, 'password')}
                                           disabled={isRPMUser || this.disableResetPassword()}>
                            Send Reset Password Email
                        </Button>
                            <h5 style={{ 'display': 'inline-block', 'marginLeft': '15px' }}>Last password reset: { lastPasswordReset }</h5>
                        </div>
                    }





                    {/*<Button bsStyle="danger"*/}
                            {/*style={buttonStyle}*/}
                            {/*disabled={disableUnenroll}*/}
                            {/*onClick={this.handleUnenroll.bind(this)}>*/}
                        {/*Request Unenroll*/}
                    {/*</Button>*/}
                    {
                        isUserAdmin && !isGAPS &&
                        <Button bsStyle="primary"
                                style={buttonStyle}
                                onClick={this.onShoeToggleCallScheduling.bind(this)}>
                            Toggle Call Scheduling
                        </Button>

                    }
                    {
                        !isGAPS &&
                        <Button bsStyle="danger"
                                style={buttonStyle}
                                disabled={disableToSupport}
                                onClick={this.handleConfirmSupport.bind(this)}>
                            Send to Patient Services
                        </Button>

                    }

	                { this.props.isCne && !isGAPS &&
		                <Button bsStyle="primary"
		                        style={buttonStyle}
		                        disabled={this.disableAdditionalInformation()}
		                        onClick={this.onAdditionalInformationEmailShowHide.bind(this)}>
			                Email Additional Information
		                </Button>
	                }

                </div>
                {
                    !isGAPS &&
                    <div className="col-md-6">
                        <div>
                            <h4>Provider Utilities</h4>
                            <Button bsStyle="primary"
                                    style={buttonStyle}
                                    onClick={this.toggleChangeProvider.bind(this)}>
                                Change Provider
                            </Button>
                        </div>

                        <h4>Patient Satisfaction Utilities</h4>
                        <Button bsStyle="primary"
                                style={buttonStyle}
                                disabled={isRPMUser}
                                onClick={this.togglePatientSatisfactionPreferences.bind(this)}>
                            Survey Preferences
                        </Button>
                    </div>
                }

                <Modal show={this.state.showConfirmReset} onHide={this.handleReset.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm password reset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Clicking "Confirm" will reset this patient's password. If you would not like to reset the password, please click "Cancel."</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" onClick={this.handleReset.bind(this)}>Cancel</Button>
                        <Button bsStyle="danger" id="confirmResetPassword" onClick={this.resetPassword.bind(this)}>Confirm</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showMergePatient} onHide={this.toggleMergePatient.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Merge Patient Accounts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MergePatientForm selectedPatient={this.props.selectedPatient} emailError={this.state.emailError} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.toggleMergePatient.bind(this)}>Cancel</Button>
                        <Button bsStyle="success" id="confirmMergeAccounts" onClick={this.mergePatient.bind(this)}>Merge Accounts</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showChangeProvider} onHide={this.toggleChangeProvider.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Patient Provider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="clearfix">
                        <div className="col-md-6">
                            <h5>Current Provider</h5>
                            <p style={{lineHeight: '30px'}}>{this.props.selectedPatient.provider && this.props.selectedPatient.provider.provider}</p>
                        </div>
                        <div className="col-md-6">
                            <h5>Select New Provider</h5>
                            <select style={ inputStyle } id="newProviderDropdown" ref="providerSelect" 
                            defaultValue={this.props.selectedPatient.provider_id} 
                            onChange={this.changeProviderSelect.bind(this)}>
                                {
                                    _.map(this.props.patientCcmProviders, (provider) => {
                                        return (
                                            <option key={provider.id} value={provider.id}>{provider.provider}</option>
                                        )
                                    })
                                }
                            </select>
                            {this.state.providerError && <span className="text-danger"><small>{this.state.providerError}</small></span>}
                        </div>
                        <div className="col-md-12">
                            <h5>Pending Provider Change Request</h5>
                            <p>{
                                this.state.requestedProvider ?
                                    `${this.state.requestedProvider} - ${this.state.requestTime}` :
                                    "No current provider change request"}</p>
                        </div>
                        {
                            newProviderId && <div className="col-md-12">
                            <h5 style={{color:'red'}}>Patient Must Re-consent to the CCM Program</h5>
                            <div className="col-md-9 remove-side-padding">
                                <Input type="checkbox" className="control-label"
                                    label={`Patient has been informed 
                                    that by making this request, they are 
                                    consenting to participation in the CCM Program under 
                                    a new provider - ${selectedProviderName}`}
                                    onChange={this.onPatientConsentReceived.bind(this)}
                                    style={{ display: 'inline-block',padding:0 }}
                                    checked={patientConsentChecked} />
                            </div>
                        </div>
                        }
                        

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.toggleChangeProvider.bind(this)}>Cancel</Button>
                        <Button bsStyle="success" disabled={!patientConsentChecked} onClick={this.changeProvider.bind(this)}>Change Provider</Button>
                    </Modal.Footer>
                </Modal>
                <UnenrollPatientPopup onPopupHide={this.handleUnenroll.bind(this)}
                      show={this.state.showUnenrollPatientPopup}
                      patient={this.props.selectedPatient} />
	            <SendToPatientServicesModal
		            onPopupHide={this.handleConfirmSupport.bind(this)}
		            show={this.state.showConfirmSupport}
		            patient={this.props.selectedPatient}
	            />
	            <UpdateContactPreferenceModal onPopupHide={this.handleUpdateContactPreference.bind(this)}
                                              show={this.state.showUpdateContactPreference}
                                              patient={this.props.selectedPatient}/>
               <ToggleCallSchedulingModal onPopupHide={this.onShoeToggleCallScheduling.bind(this)}
                                          show={this.state.showToggleCallScheduling}
                                          patient={this.props.selectedPatient}/>
                <EditPatientInformationPopup onPopupHide={this.onEditPatientInfoShowHide.bind(this)} show={this.state.showEditPatientInfo} patientInfo={data} />
				<UpdatePatientMailingAddress
					onHide={this.onMailingAddressShowHide.bind(this)}
					show={this.state.showMailingAddress}
					onConfirm={this.onConfirmMailingAddressUpdate.bind(this)}
					patient={data}
				/>
	            <AdditionalInformationEmailModal
		            onHide={this.onAdditionalInformationEmailShowHide.bind(this)}
		            show={this.state.showAdditionalInformationEmail}
		            onConfirm={this.onConfirmAdditionalInformationEmail.bind(this)}
		            patient={data}
	            />
	            <PatientSatisfactionPreferenceModal
	                onHide={this.togglePatientSatisfactionPreferences.bind(this)}
	                show={this.state.showPatientSatisfactionPreferences}
	                onConfirm={this.onConfirmPatientSatisfactionPreference.bind(this)}
		            patient={data}
	                inputStyle={inputStyle}
	                updateComponent={this.updatePatientSatisfactionPreference.bind(this)}
	            />
                 <PowerOfAttorneyModal onPopupHide={this.onPOAUpdateShowHide.bind(this)}
                                          show={this.state.showPOAUpdate}
                                          patient={this.props.selectedPatient}/>
            </Panel>
        )
    }
}

const mapStateToProps = (state) => ({
    'phsAvailable': state.levelTwoDashboard.phsAvailable,
    'phsPdfUrl': state.levelTwoDashboard.phsPdfUrl,
    'userRole':state.auth.roles,
    'selectedPatient': state.app.selectedPatient,
    'form': state.form,
    'app': state.app,
    'patientCcmProviders': state.app.patientCcmProviders,
    'activeUser': state.admin.activeUser
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Utilities);
