/**
 * Created by synerzip on 08/12/15.
 */
import { checkHttpStatus, parseJSON,isAdmin,isMutiTeamUser,isTriage, isMA } from '../../utils';
import CONSTANTS from '../../constants';
import config from '../../config';
import { showToast, hideToast, get, post, deleteRequest, put } from '../common';
import { pushState } from 'redux-router';
import _ from 'lodash';
import $ from 'jquery';
import { getValues } from 'redux-form';
import moment from 'moment';

const {REQUEST_SENT,
    REQUEST_FAIL,
    UNASSIGNED_PATIENTS_RECEIVED,
    MEDICAL_RECORD_RECEIVED,
    CLEAR_MATCH_APPROVAL_PATIENT,
    MA_DETAIL_RECEIVED,
    PATIENT_DATA_RECEIVED,
    MEDICAL_ASSISTANTS_RECEIVED,
    MA_ASSIGNED_SUCCESSFULLY,
    INBOUND_MEDICAL_RECORD_RECEIVED,
    MEDICAL_DOC_RECEIVED,
    INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED,
    LEVEL_TWO_DASHBOARD_DATA_RECEIVED,
    PATIENT_SSO_TOKEN_RECEIVED,
    PATIENT_DETAIL_RECEIVED,
    TODO_ADDED_SUCCESSFULLY,
    TODOS_MARK_COMPLETED,
    TODO_MARK_UNCOMPLETED,
    CASE_HISTORY_RECEIVED,
    OPENED_PATIENT_ID,
    ASSESSMENTS_RECEIVED,
    ASSESSMENT_ADDED,
    ASSESSMENT_DELETED,
    ASSESSMENT_UPDATED,
    NOTES_RECEIVED,
    PATIENT_VERIFIED,
    PATIENT_SEARCH_RESULT_RECEIVED,
    CONSENT_FORM_RECORD_SELECTED,
    MEDICAL_RECORD_MATCHED_SUCCESSFULLY,
    CARE_PLAN_AND_GOALS_RECEIVED,
    GOAL_COMPLETED_SUCCESSFULLY,
    CLEAR_SEARCHED_PATIENT_LIST,
    MATCH_APPROVAL_PATIENT_SELECTED,
    CHART_OPEN_BY_REQUEST,
    CHART_OPEN_BY_REQUEST_SUCCESS,
    CHART_OPEN_BY_REQUEST_FAIL,
    CHART_CLOSED_SUCCESSFULLY,
    TODO_UPDATED_SUCCESSFULLY,
    INITIATE_UNENROLL_PATIENT_REQUEST,
    INITIATE_UNENROLL_PATIENT_SUCCESS,
    INITIATE_UNENROLL_PATIENT_FAIL,
    GET_OPEN_CHARTS_REQUEST,
    GET_OPEN_CHARTS_SUCCESS,
    GET_OPEN_CHARTS_FAIL,
    ADMIN_CLOSE_CHART_REQUEST,
    ADMIN_CLOSE_CHART_SUCCESS,
    ADMIN_CLOSE_CHART_FAIL,
    SSO_TOKEN_RECEIVED,
    GOAL_ADDED_SUCCESSFULLY,
    GOAL_STEP_ADDED_SUCCESSFULLY,
    GOAL_STEP_UPDATED_SUCCESSFULLY,
    GOAL_STEP_DELETED_SUCCESSFULLY,
    GOAL_DELETED_SUCCESSFULLY,
    GOAL_UPDATED_SUCCESSFULLY,
    STEP_UPDATED_SUCCESSFULLY,
    NON_ACCOUNT_PATIENT_DETAIL_RECEIVED,
    ACCOUNT_CREATED_SUCCESSFULLY,
    PATIENT_CCM_PROVIDERS_RECEIVED,
    GET_CCDA_AVAILABILITY_REQUEST,
    GET_CCDA_AVAILABILITY_SUCCESS,
    GET_CCDA_AVAILABILITY_FAIL,
    GET_CCDA_PDF_REQUEST,
    GET_CCDA_PDF_SUCCESS,
    GET_CCDA_PDF_FAIL,
    CALL_LOG_SELECTIONS_RECEIVED,
    GET_PHS_AVAILABILITY_REQUEST,
    GET_PHS_AVAILABILITY_SUCCESS,
    GET_PHS_AVAILABILITY_FAIL,
    LAB_UNITS_RECEIVED,
    LABS_RECEIVED,
    LAB_DELETED,
    LAB_CREATED,
    ASSESSMENT_QUESTIONS_RECEIVED,
    ASSESSMENT_ANSWERS_RECEIVED,
    UPDATE_ASSESSMENT_ANSWERS,
    UPDATE_GAPS_ASSESSMENT_ANSWERS,
    UPDATE_ASSESSMENT_UPDATES,
    GET_PHS_PDF_REQUEST,
    GET_PHS_PDF_SUCCESS,
    GET_PHS_PDF_FAIL,
    STEP_ADDED_SUCCESSFULLY,
    PATIENT_DETAILS_UPDATED,
    RESET_PATIENT_UPDATE_STATUS,
    REQUEST_RECORD_INVALID,
    REQUEST_RECORD_VALID,
    SUBMIT_REQUEST_VALID,
    RESET_PASSWORD_COMPLETE,
    RECEIVED_PROVIDER_LIST,
    RECEIVED_REQUEST_HISTORY,
    GET_OPEN_RECORDS,
    SENT_RECORD_REQUESTS_SUCCESS,
    MERGED_ACCOUNTS,
    ACTIVE_USER_RECEIVED,
    COMPANIES_RECEIVED,
    RECEIVED_REPORTING_DATA,
    REQUEST_COMPLETE,
    CLEAR_MEDICAL_FORM_DOC,
    SORT_REPORTS_ASCENDING,
    SORT_REPORTS_DESCENDING,
    RECEIVED_LAST_PASSWORD_RESET,
    RECORDS_RECEIVED,
    GET_RECORD_SUCCESS,
    DELETE_INBOUND_RECORD_SUCCESS,
    GET_MED_SUMMARY_AVAILABILITY_SUCCESS,
    GET_MED_SUMMARY_PDF_REQUEST,
    GET_MED_SUMMARY_PDF_SUCCESS,
    GET_MED_SUMMARY_PDF_FAILURE,
    GET_MED_SUMMARY_AVAILABILITY_FAILURE,
    FETCH_ORGS_SUCCESS,
    FETCH_LOCATIONS_SUCCESS,
    FETCH_QUEUE_REQUEST,
    FETCH_QUEUE_SUCCESS,
    FETCH_QUEUE_FAILURE,
    FETCH_CALL_LOG_CODES_SUCCESS,
    SEARCH_PATIENTS_SUCCESS,
    SEARCH_PATIENTS_FAILURE,
    URGENT_TODO_TYPES_RECEIVED,
    UTILIZATION_RECEIVED,
    WHITEBOARD_EXISTS,
    WHITEBOARD_CONTENT,
    UPDATE_WHITEBOARD_CONTENT,
    OPEN_WHITEBOARD,
    CNE_PERFORMANCE_RECEIVED,
    WORK_SUMMARY_RECEIVED,
    CLEAR_WORK_SUMMARY,
    RECEIVED_UNENROLL_REASONS,
    REQUEST_CHART_CLOSURE,
    REQUEST_CHART_CLOSURE_COMPLETED,
    CHART_CLOSURE_REQUEST_FOUND,
    REMOVE_CHART_CLOSURE_REQUEST,
    MAILING_ADDRESS_UPDATED,
    SEND_TO_SUPPORT_SUCCESS,
    SEND_TO_SUPPORT_FAILED,
    RECEIVED_PATIENT_SURVEY_QUESTION,
    RECEIVED_PATIENT_SURVEY_SUBMISSTION,
    RECEIVED_ORGANIZATION_NOTE,
    RECEIVED_PATIENT_SERVICES_REASONS,
    UPDATE_PATIENT_SATISFACTION_PREFERENCES,
    RECEIVED_ASSOCIATED_CHARTS,
    FAX_MATCHING_PATIENT_LIST_RECEIVED,
    FAX_QUEUE_RECORD_RECEIVED,
    RECORD_FIXED_SUCCESSFULLY,
    RECORD_ARCHIVE_SUCCESSFULLY,
    RECORD_UMMATCHED_DATA_RECEIVED,
    RESET_UNMATCHED_DATA,
    INBOUND_RECORDS_RECEIVED_TO_FIX_QUEUE,
    FAX_APPROVAL_QUEUE_RECORD_RECEIVED,
    TODO_SOCIAL_DETERMINANTS_RECEIVED,
    OPT_OUT_REASONS_RECEIVED,
    RESET_SEARCH_RESULE,
    ON_PATIENT_PHARMACY_OPTIONS_RECEIVED,
    ON_PATIENT_CHARTSPAN_PROVIDERS_RECEIVED,
    CARE_PLAN_TYPES,
    PATIENT_CARE_PLAN_RECEIVED,
    CARE_PLAN_QUESTION_RECEIVED,
    GAPS_ANSWERS_RECEIVED, MAKE_CALL_SUCCESS,
    GAPS_HISTORY_RECEIVED,
    CALL_ASSISTANCE_TYPES,
    PATIENT_EDUCATIONS_RECEIVED,
    EDUCATION_ADDED_SUCCESSFULLY,
    ON_PATIENT_MEDICATION_OPTIONS_RECEIVED,
    ON_GOAL_REVIEW_FREQUENCY_RECEVIED,
    PATIENT_ENGAGEMENT_HISTIRY_RECEIVED,
    ON_TEAMS_RECEVIED,
    ON_CLINICIANS_RECEIVED,
    ON_GOAL_TYPES_RECEIVED,
    ON_GOAL_SOURCES_RECEIVED,
    ON_GOAL_COMPLETION_STATUS_RECEIVED,
    ON_GOAL_STEP_TYPES_RECEIVED,
    PATIENT_LAST_VISITED_DATE_RECEIVED,
    ON_OPEN_CHART_SUMMARY,
    ON_CLOSE_CHART_SUMMARY,
    ON_OPEN_CHART_TAB,
    MYQUEUE_COUNTS_RECEIVED,
    ON_ALL_CLINICIANS_RECEIVED,
    ALLPATIENTS_COUNTS_RECEIVED} = CONSTANTS;
export function getUnAssignedPatients() {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/unassigned";
        get(endpoint)
            .then((result) => {
                //Get MAs
                let endpoint = "/v1/mas";
                get(endpoint)
                    .then((maresult) => {
                        console.log(maresult);
                        dispatch({
                            type: UNASSIGNED_PATIENTS_RECEIVED, payload: {
                                unAssignedPatients: result,
                                medicalAssistants: maresult

                            }
                        })
                    })
                    .catch(error => {
                        throw error;
                    });


            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getALLMedicalAssistants() {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/mas";
        get(endpoint)
            .then((result) => {
                dispatch({ type: MEDICAL_ASSISTANTS_RECEIVED, payload: result });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getMADetail(maId) {
    return (dispatch) => {
        let nodeURL = "/v1/ma/" + maId;
        dispatch({ type: REQUEST_SENT });
        get(nodeURL)
            .then((result) => {
                dispatch({ type: MA_DETAIL_RECEIVED, payload: result })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getInboundMedicalRecords() {
    return (dispatch) => new Promise((resolve, reject) => {
        const nodeURL = "/v1/inbound/records";
        dispatch({ type: REQUEST_SENT });
        get(nodeURL)
            .then((result) => {
                dispatch({ type: INBOUND_MEDICAL_RECORD_RECEIVED, payload: result })
                resolve(true)
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    })
}



export function getInboundMatchedMedicalRecords() {
    return (dispatch) => new Promise((resolve, reject) => {
        const nodeURL = "/v1/inbound/records/matches";
        dispatch({ type: REQUEST_SENT });
        get(nodeURL)
            .then((result) => {
                dispatch({ type: INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED, payload: result })
                resolve(true)
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    })
}

export function redirectToRecords() {
    return (dispatch) => {
        dispatch(pushState(null, `/home/records`));
    }
}

export function onConsentFormDocSelected(doc) {
    return ({ type: CONSENT_FORM_RECORD_SELECTED, payload: doc });
}

export function getMedicalFormDoc(consentFormId) {

    let loggedinUser = localStorage.getItem('ccmLoggedinUser');
    let loggedinUserObj = null;
    if (loggedinUser !== null) {
        //alert(JSON.parse(loggedinUser));
        loggedinUserObj = JSON.parse(loggedinUser);
    }
    let nodeURL = "/v1/inbound/records/" + consentFormId + "/file";
    let type = "application/pdf";
    return dispatch => {
        dispatch({ type: REQUEST_SENT });
        fetch(config.BASE_URL + nodeURL, {
            method: 'get',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': loggedinUserObj.token
            }
        })
            .then(checkHttpStatus)
            .then(response => {
                let disposition = response.headers.get('Content-Disposition');

                type = response.headers.get('Content-Type');
                return response.blob();
            }).then(response => {
                let blob = new Blob([response], { type: type });
                let reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    dispatch({
                        type: MEDICAL_DOC_RECEIVED,
                        payload: {
                            url: URL.createObjectURL(blob),
                            doc: base64data.substr(base64data.indexOf(',') + 1)
                        },
                    }
                    );
                }


            })
            .catch(err => {
                dispatch({ type: REQUEST_FAIL });

                dispatch(showToast({
                    status: 'error',
                    title: "Download",
                    message: "An error occured while dowloading the file."
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);

                console.log('Download err:-', err);
            });
    }
}

export function getMatchApprovalPatient(data) {
    return ({ type: MATCH_APPROVAL_PATIENT_SELECTED, payload: data });
}

export function getGoals(patientId) {
    return (disptach) => {
        disptach({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/goals";
        get(endpointURL).
            then((response) => {
                disptach({ type: CARE_PLAN_AND_GOALS_RECEIVED, payload: response.reverse() });
            }).
            catch(error => {
                console.log(error);
            });
    }
}

export function redirectIfChartOpen(userId) {
    return (dispatch) =>
        get(`/v1/mas/${userId}`)
            .then((response) => {
                if (response.active_patient_id) {
                    get(`/v1/patients/${response.active_patient_id}`)
                        .then((patient) => {
                            dispatch(getDetail(patient, true));
                            dispatch(showToast({
                                status: 'success',
                                title: 'Chart Opened',
                                message: `Chart for ${patient.first_name} ${patient.last_name} openend`,
                            }));
                            let interval = setInterval(() => {
                                clearInterval(interval);
                                dispatch(hideToast());
                            }, 2);
                        });
                }
            })
            .catch(() => false);
}

export function getMyQueueCounts(filter){
    return (dispatch) => {
        get(`/v1/queue/patients/MyQueue/counts${filter}`)
            .then((response) => {
                dispatch({ type: MYQUEUE_COUNTS_RECEIVED, payload: response });
            })
            .catch(() => false);
    }
}

export function getMyTeamQueueCounts(filter){
    return (dispatch) => {
        get(`/v1/queue/patients/MyTeamQueue/counts${filter}`)
            .then((response) => {
                dispatch({ type: MYQUEUE_COUNTS_RECEIVED, payload: response });
            })
            .catch(() => false);
    }
}

export function getAllPatientsQueueCounts(){
    return (dispatch) => {
        get(`/v1/queue/patients/All/counts`)
            .then((response) => {
                dispatch({ type: ALLPATIENTS_COUNTS_RECEIVED, payload: response });
            })
            .catch(() => false);
    }
}


export function fetchAllPatientQueue(
    queue,
    endpoint,
    pageNum = 0,
    pageSize = 15,
    orgId = null,
    locationId = null,
    providerId = null,
    showFilters = false,
    showSort = false,
    sortBy = null,
    showProgramTypeFilter = null,
    programType = null,
    showScheduleCallFilter = null,
    scheduledCallStatus = null,
    gapsEngagementStatus = null,
    preferredLanguage = null,
    selectedTeam,
    selectedClinician,
    callAttempts,
    completedChecked,
    urgentType,
    reEngageFiltering,
    clinicalServiceGoal,
    selectedTeamForAllPateints,
    selectedClinicianForAllPatients,
    selectedGeographics,
    selectedTimezones,
    allPatientsCallAttempts,
    connectedCall){
        return (dispatch,getState) => {
            const activeUser = getState().admin.activeUser;
            const user =  getState().auth
            const isUserAdmin = isAdmin(user.roles);
            const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
            const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
            const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
            const isRetSpec = activeUser && activeUser.center && activeUser.center.support_flag;
            
            if(!scheduledCallStatus){
                scheduledCallStatus = 'all';
            }
            const allPatientFilter = {};
            allPatientFilter.scheduledCallStatus = scheduledCallStatus;

            if (orgId) {
                allPatientFilter["orgIds"] = [orgId];
            }else{
                allPatientFilter["orgIds"] = [];
            }

            if (locationId) {
                allPatientFilter["locationIds"] = [locationId];
            }else{
                allPatientFilter["locationIds"] = [];
            }

            if (providerId) {
                allPatientFilter["providerIds"] = [providerId];
            }else{
                allPatientFilter["providerIds"] = [];
            }

            if(preferredLanguage){
                allPatientFilter["preferredLanguage"] = preferredLanguage;
            }

            if(clinicalServiceGoal){
                allPatientFilter["clinicalGoal"] = clinicalServiceGoal;
            }
            
            allPatientFilter["completed"] = completedChecked ? completedChecked : false;

            if(urgentType){
                allPatientFilter["urgentTypeIds"] = [urgentType];
            }

            if(allPatientsCallAttempts && allPatientsCallAttempts.length > 0){
                allPatientFilter["callAttempts"] = _.map(allPatientsCallAttempts,data => data.id);
            }

            if(selectedClinicianForAllPatients && selectedClinicianForAllPatients.length > 0){
                allPatientFilter["clinicianIds"] = _.map(selectedClinicianForAllPatients,data => data.id);
            }

            if(selectedTeamForAllPateints && selectedTeamForAllPateints.length > 0){
                allPatientFilter["teamIds"] =  _.map(selectedTeamForAllPateints,data => data.id);
            }

            if(selectedGeographics && selectedGeographics.length > 0){
                allPatientFilter["selectedStates"] = _.map(selectedGeographics,data => data.id);
            }

            if(selectedTimezones && selectedTimezones.length > 0){
                allPatientFilter["timezones"] = _.map(selectedTimezones,data => data.id);
            }

            allPatientFilter["connectedCallsInCurrentMonth"] = connectedCall || false;
            
            dispatch({ type: FETCH_QUEUE_REQUEST });
            const headers = {
                'X-Page': pageNum,
                'X-Page-Size': pageSize,
            };
            post(`/v1/queue/patients/${endpoint}`, allPatientFilter,headers)
            .then((response) => {
                dispatch({
                    type: FETCH_QUEUE_SUCCESS,
                    payload: response,
                    queue,
                    orgId,
                    locationId,
                    providerId,
                    showFilters,
                    showSort,
                    sortBy,
                    programType,
                    preferredLanguage,
                    gapsEngagementStatus,
                    scheduledCallStatus,
                    selectedTeam,
                    selectedClinician,
                    callAttempts,
                    completedChecked,
                    urgentType,
                    reEngageFiltering,
                    clinicalServiceGoal,
                    selectedTeamForAllPateints,
                    selectedClinicianForAllPatients,
                    selectedGeographics,
                    selectedTimezones,
                    allPatientsCallAttempts,
                    connectedCall
                });
            })
            .catch(() => {
                dispatch({
                    type: FETCH_QUEUE_FAILURE,
                    queue,
                    orgId,
                    locationId,
                    providerId,
                    showFilters,
                    showSort,
                    sortBy,
                    programType,
                    preferredLanguage,
                    scheduledCallStatus,
                    selectedTeam,
                    selectedClinician,
                    callAttempts,
                    completedChecked,
                    urgentType,
                    reEngageFiltering,
                    clinicalServiceGoal,
                    selectedTeamForAllPateints,
                    selectedClinicianForAllPatients,
                    selectedGeographics,
                    selectedTimezones,
                    allPatientsCallAttempts,
                    connectedCall
                });
            });
        }
       

}

export function fetchQueue(
    queue,
    endpoint,
    pageNum = 0,
    pageSize = 15,
    orgId = null,
    locationId = null,
    providerId = null,
    showFilters = false,
    showSort = false,
    sortBy = null,
    showProgramTypeFilter = null,
    programType = null,
    showScheduleCallFilter = null,
    scheduledCallStatus = null,
    gapsEngagementStatus = null,
    preferredLanguage = null,
    selectedTeam,
    selectedClinician,
    callAttempts,
    completedChecked,
    urgentType,
    reEngageFiltering,
    clinicalServiceGoal,
    selectedTeamForAllPateints,
    selectedClinicianForAllPatients,
    selectedGeographics,
    selectedTimezones,
    allPatientsCallAttempts,
    connectedCall
) {
    return (dispatch,getState) => {
        const activeUser = getState().admin.activeUser;
        const user =  getState().auth
        const isUserAdmin = isAdmin(user.roles);
        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
        const isRetSpec = activeUser && activeUser.center && activeUser.center.support_flag;
        if(!isGAPS && !isRPM && !isCne  && !isRetSpec){
            if(!isUserAdmin){
                if(!activeUser.team_id){
                    selectedTeam = "0";
                }
            }
        }

       
        if(queue === 'allPatients'){
            dispatch(fetchAllPatientQueue(queue,
                endpoint,
                pageNum ,
                pageSize,
                orgId,
                locationId,
                providerId,
                showFilters,
                showSort,
                sortBy,
                showProgramTypeFilter,
                programType,
                showScheduleCallFilter,
                scheduledCallStatus,
                gapsEngagementStatus,
                preferredLanguage,
                selectedTeam,
                selectedClinician,
                callAttempts,
                completedChecked,
                urgentType,
                reEngageFiltering,
                clinicalServiceGoal,
                selectedTeamForAllPateints,
                selectedClinicianForAllPatients,
                selectedGeographics,
                selectedTimezones,
                allPatientsCallAttempts,
                connectedCall));
            return;
        }
        if(scheduledCallStatus == null && !isCne){
            scheduledCallStatus = 'today';
        }
        let filter = '';

        let endPointSuffix = `${endpoint}${filter}`
        if (orgId && showFilters) {
                if (endpoint && endpoint.indexOf('?') !== -1) {
                    filter += `&filter=${orgId}`;
                } else {
                    filter += `?filter=${orgId}`;
                }
                if(locationId){
                    filter += `${encodeURI('|')}${locationId}`;
                }
                if(providerId){
                    filter += `${encodeURI('|')}${providerId}`;
                }
                endPointSuffix = `${endpoint}${filter}`
        }
        

        if (sortBy && showSort && orgId) filter += `&sort=${sortBy}`;
        if (sortBy && showSort && !orgId) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&sort=${sortBy}`;
            } else {
                filter += `?sort=${sortBy}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }
        if (programType && showProgramTypeFilter) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&programType=${programType}`;
            } else {
                filter += `?programType=${programType}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }

        if (!isGAPS && scheduledCallStatus && showScheduleCallFilter) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&scheduledCallStatus=${scheduledCallStatus}`;
            } else {
                filter += `?scheduledCallStatus=${scheduledCallStatus}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }
        if (preferredLanguage) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&preferredLanguage=${preferredLanguage}`;
            } else {
                filter += `?preferredLanguage=${preferredLanguage}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }

        if(gapsEngagementStatus && isGAPS){
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&engagementStatus=${gapsEngagementStatus}`;
            } else {
                filter += `?engagementStatus=${gapsEngagementStatus}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }

        if (showFilters && selectedTeam && queue !== 'myTeamPatients') {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&teamId=${selectedTeam}`;
            } else {
                filter += `?teamId=${selectedTeam}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }

        if(!isGAPS && !isRPM && !isCne  && !isRetSpec){
            if (showFilters && selectedClinician && (selectedTeam != 0 || queue === 'allPatients')) {
                if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                    filter += `&clinicianId=${selectedClinician}`;
                } else {
                    filter += `?clinicianId=${selectedClinician}`;
                }
                endPointSuffix = `${endpoint}${filter}`
            }
        }
        

        if (callAttempts) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&callAttempts=${encodeURIComponent(callAttempts)}`;
            } else {
                filter += `?callAttempts=${encodeURIComponent(callAttempts)}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }
        if (urgentType) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&urgentTypeId=${encodeURIComponent(urgentType)}`;
            } else {
                filter += `?urgentTypeId=${encodeURIComponent(urgentType)}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }
        if (reEngageFiltering) {
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&reEngageFiltering=${encodeURIComponent(reEngageFiltering)}`;
            } else {
                filter += `?reEngageFiltering=${encodeURIComponent(reEngageFiltering)}`;
            }
            endPointSuffix = `${endpoint}${filter}`
        }

        

        if(completedChecked == false){
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&completed=${encodeURIComponent(completedChecked)}`;
            } else {
                filter += `?completed=${encodeURIComponent(completedChecked)}`;
            }
            endPointSuffix = `${endpoint}${filter}`   
        }

        if(clinicalServiceGoal){
            if (endPointSuffix && endPointSuffix.indexOf('?') !== -1) {
                filter += `&goal=${encodeURIComponent(clinicalServiceGoal)}`;
            } else {
                filter += `?goal=${encodeURIComponent(clinicalServiceGoal)}`;
            }
            endPointSuffix = `${endpoint}${filter}`  
        }

        dispatch({ type: FETCH_QUEUE_REQUEST });
        const headers = {
            'X-Page': pageNum,
            'X-Page-Size': pageSize,
        };
        get(`/v1/queue/patients/${endPointSuffix}`, headers)
            .then((response) => {
                dispatch({
                    type: FETCH_QUEUE_SUCCESS,
                    payload: response,
                    queue,
                    orgId,
                    locationId,
                    providerId,
                    showFilters,
                    showSort,
                    sortBy,
                    programType,
                    preferredLanguage,
                    gapsEngagementStatus,
                    scheduledCallStatus,
                    selectedTeam,
                    selectedClinician,
                    callAttempts,
                    completedChecked,
                    urgentType,
                    reEngageFiltering,
                    clinicalServiceGoal
                });
            })
            .catch(() => {
                dispatch({
                    type: FETCH_QUEUE_FAILURE,
                    queue,
                    orgId,
                    locationId,
                    providerId,
                    showFilters,
                    showSort,
                    sortBy,
                    programType,
                    preferredLanguage,
                    scheduledCallStatus,
                    selectedTeam,
                    selectedClinician,
                    callAttempts,
                    completedChecked,
                    urgentType,
                    reEngageFiltering,
                    clinicalServiceGoal
                });
            });
    }
}

export function resetSearch() {
    return (dispatch) => {
        dispatch({
            type: SEARCH_PATIENTS_SUCCESS,
            payload: [],
            searchTerm: '',
            searchDate: '',
        })
    }
}

export function fetchCallLogCodes() {
    return (dispatch) =>
        get('/v1/codes/quickcalllogs')
            .then((response) => {
                dispatch({ type: FETCH_CALL_LOG_CODES_SUCCESS, payload: response });
            })
            .catch(() => false);
}

function refetchQueue(queue, dispatch, getState) {
    const queueState = getState().queue;
    const { [`${queue}Meta`]: { page }, pageSize, orgId, locationId, providerId, showFilters, showSort, sortBy } = queueState;
    dispatch(fetchQueue(queue, page, pageSize, orgId, locationId, providerId, showFilters, showSort, sortBy));
}

export function createQuickWorkItem(patientId, data) {
    return (dispatch, getState) =>
        post(`/v1/patients/${patientId}/quickworkitem`, data)
            .then((response) => {
                dispatch(showToast({
                    status: 'success',
                    title: 'Success',
                    message: 'Work item saved',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                refetchQueue('unverified', dispatch, getState);
            })
            .catch(() => {
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message: 'Unable to save work item',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
}

export function markUnComplete(patientId, todoId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/todos/" + todoId + "/uncomplete";
        post(endpointURL, {}).
            then((result) => {
                console.log(result);
                dispatch({ type: TODO_MARK_UNCOMPLETED, payload: result })
            }).
            catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
            });
    }
}

export function deleteAssessment(patientId, assessmentId, assessmentType) {
    return (dispatch) => {
        let endpoint = "/v1/patients/" + patientId + "/assessments/" + assessmentId;
        dispatch({ type: REQUEST_SENT });
        deleteRequest(endpoint).
            then((result) => {
                console.log(result);
                dispatch({
                    type: ASSESSMENT_DELETED,
                    payload: { assessmentId: assessmentId, assessmentType: assessmentType }
                });
                dispatch(showToast({
                    status: 'success',
                    title: 'Delete Assessment',
                    message: 'Successfully Deleted Assessment'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Delete Assessment',
                    message: 'Failed to Delete Assessment'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function editAssessment(patientId, data) {
    let endpoint = "/v1/patients/" + patientId + "/assessments/" + data.id;
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        put(endpoint, data).
            then((result) => {
                console.log(result);
                dispatch({ type: ASSESSMENT_UPDATED, payload: result });
                dispatch(showToast({
                    status: 'success',
                    title: 'Update Assessment',
                    message: 'Successfully Update Assessment'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Update Assessment',
                    message: 'Failed to Update Assessment'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function addAssessment(patientId, data) {
    let endpoint = "/v1/patients/" + patientId + "/assessments";
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        post(endpoint, data).
            then((result) => {
                console.log(result);
                dispatch({ type: ASSESSMENT_ADDED, payload: result });
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Add Assessments',
                    message: 'Failed to Add Assessments'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function refetchSearchPatients(lastName, dob, phone, ccmId) {
    return (dispatch) => {
        const nameQ = lastName ? `queryLastname=${lastName}` : '';
        const dobQ = dob ? `queryDob=${dob}` : '';
        const phoneQ = phone ? `queryPhone=${phone}` : '';
        const ccmIdQ = ccmId ? `queryId=${ccmId}` : '';
        const amp = lastName && dob ? '&' : '';
        const amp2 = phone && (lastName || dob) ? '&' : '';
        const amp3 = ccmId && (lastName || dob || phone) ? '&' : '';
        dispatch({ type: REQUEST_SENT });
        get(`/v1/patients/search?${nameQ}${amp}${dobQ}${amp2}${phoneQ}${amp3}${ccmIdQ}`)
            .then((response) => {
                console.log(response)
                dispatch({
                    type: SEARCH_PATIENTS_SUCCESS,
                    payload: response,
                    searchTerm: lastName,
                    searchDate: dob,
                });
            })
            .catch(() => {
                dispatch({
                    type: SEARCH_PATIENTS_FAILURE,
                    searchTerm: lastName,
                    searchDate: dob,
                });
            });
    };
}

export function searchPatients(lastName, dob, phone, ccmId,email) {
    return (dispatch) => {
        const nameQ = lastName ? `queryLastname=${lastName}` : '';
        const dobQ = dob ? `queryDob=${dob}` : '';
        const phoneQ = phone ? `queryPhone=${phone}` : '';
        const ccmIdQ = ccmId ? `queryId=${ccmId}` : '';
        const emailQ = email ? `queryEmail=${email}` : '';
        const amp = lastName && dob ? '&' : '';
        const amp2 = phone && (lastName || dob) ? '&' : '';
        const amp3 = ccmId && (lastName || dob || phone) ? '&' : '';
        const amp4 = email && (lastName || dob || phone || ccmId) ? '&' : '';
        dispatch({ type: REQUEST_SENT });
        get(`/v1/patients/search?${nameQ}${amp}${dobQ}${amp2}${phoneQ}${amp3}${ccmIdQ}${amp4}${emailQ}`)
            .then((response) => {
                dispatch({
                    type: SEARCH_PATIENTS_SUCCESS,
                    payload: response,
                    searchTerm: lastName,
                    searchDate: dob,
                });
            })
            .catch(() => {
                dispatch({
                    type: SEARCH_PATIENTS_FAILURE,
                    searchTerm: lastName,
                    searchDate: dob,
                });
            });
    };
}

export function searchPatient(lastNameQuery, dateOfBirthQuery, callback) {

    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });

        let endpointURL = "/v1/patients/search";
        let requestParam = "";
        if (lastNameQuery) {
            requestParam += "queryLastname=" + lastNameQuery;
            if (dateOfBirthQuery) {
                requestParam += "&queryDob=" + dateOfBirthQuery;
            }
        } else {
            if (dateOfBirthQuery) {
                requestParam += "queryDob=" + dateOfBirthQuery;
            }
        }
        get(endpointURL + "?" + requestParam).
            then((response) => {
                console.log(response);
                dispatch({ type: PATIENT_SEARCH_RESULT_RECEIVED, payload: response });

                if (callback) {
                    callback();
                }
            }).
            catch(error => {
                console.log(error);

                dispatch({ type: REQUEST_FAIL });

                if (callback) {
                    callback();
                }
            })
    }
}

export function clearSearchedPatientList() {
    return {
        type: CLEAR_SEARCHED_PATIENT_LIST
    }
}

export function linkAccount(patinetId) {
    return (dispatch => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/sso/chartspan/" + patinetId + "/link";
        post(endpointURL, {}).
            then((response) => {
                if (response.status == 'Success') {
                    dispatch(showToast({
                        status: 'success',
                        title: 'Link Account',
                        message: "Linked Account Successfully"

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                    dispatch(getSSODetail(patinetId));
                    dispatch({ type: ACCOUNT_CREATED_SUCCESSFULLY, payload: response.patient })

                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Link Account',
                        message: "Error to Link Account"

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Link Account',
                    message: "Error to Link Account"

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    });
}

export function createAndLinkAccount(userName, patinetId) {
    return (dispatch => {
        dispatch({ type: REQUEST_SENT });
        get("/v1/sso/chartspan/username/exists?username=" + userName).
            then((result) => {
                if (!result) {
                    //Create and Link account
                    let createURL = "/v1/sso/chartspan/" + patinetId + "/create?username=" + userName;
                    post(createURL, {}).
                        then((response) => {
                            if (response.status == "Success") {
                                dispatch(getSSODetail(patinetId));
                                dispatch({ type: ACCOUNT_CREATED_SUCCESSFULLY, payload: response.patient })

                            } else {
                                dispatch({ type: REQUEST_FAIL });
                                dispatch(showToast({
                                    status: 'error',
                                    title: 'Create Account',
                                    message: response.reason

                                }));
                                let interval = setInterval(() => {
                                    clearInterval(interval);
                                    dispatch(hideToast());
                                }, 2);
                            }
                        }).
                        catch(error => {
                            throw error;
                        })
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Create Account',
                        message: 'Username already exist'

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }

            }).
            catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Create Account',
                    message: 'Failed to create account'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    })
}

export function getNonExistPatientInfo(patient) {
    return (dispatch => {
        dispatch(
            {
                type: NON_ACCOUNT_PATIENT_DETAIL_RECEIVED,
                payload: {
                    linkAccountAvailable: false
                }
            });
        //dispatch({type: REQUEST_SENT});
        //let checkAccountAvailableURL = "/v1/sso/chartspan/" + patient.id + "/exists";
        //get(checkAccountAvailableURL).
        //    then((result)=> {
        //        console.log(result);
        //        if (result.status == 'Success') {
        //            dispatch(
        //                {
        //                    type: NON_ACCOUNT_PATIENT_DETAIL_RECEIVED,
        //                    payload: {
        //                        linkAccountAvailable: true,
        //                        linkAccountData:result.chartspan_account
        //                    }
        //                });
        //        } else if (result.status == 'Failure') {
        //            dispatch(
        //                {
        //                    type: NON_ACCOUNT_PATIENT_DETAIL_RECEIVED,
        //                    payload: {
        //                        linkAccountAvailable: false
        //                    }
        //                });
        //        }
        //    }).
        //    catch(error=> {
        //        console.log(error);
        //    })
    })
}

export function verifyPatient(patient, callType = null,isRPM=false) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patient.id + "/verify";
        if(isRPM){
            endpointURL = "/v1/patients/" + patient.id + "/rpm/verify";
        }
        return put(endpointURL, { consent_type: callType })
            .then((response) => {
                if (response.status == "Success") {
                    dispatch({ type: PATIENT_VERIFIED, payload: patient });
                    dispatch(showToast({
                        status: 'success',
                        title: 'Verify',
                        message: 'Patient Verified Successfully'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Verify',
                        message: response.reason
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })
            .catch(error => {
                parseJSON(error).then((errorObj) => {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Verify',
                        message: errorObj.message
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                });
            })
    }
}

export function updateAddress(patient, address1, address2, city, state, zip_code, ok_to_text, mobile,callback) {
    return (dispatch) => {
        patient.address1 = address1;
        patient.address2 = address2;
        patient.city = city;
        patient.state = state;
        patient.zip_code = zip_code;
        patient.ok_to_text = ok_to_text;
        if (mobile) {
            patient.mobile = mobile
        }
        dispatch({ type: REQUEST_SENT });
        const endpointURL = `/v1/patients/${patient.id}`;
        return put(endpointURL, patient)
            .then((response) => {
                if (response.status == "Success") {
                    dispatch({ type: MAILING_ADDRESS_UPDATED, payload: patient });
                    if(callback){
                        callback();
                    }
                    dispatch(showToast({
                        status: 'success',
                        title: 'Address',
                        message: 'Patient Address Saved Successfully'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Address',
                        message: 'Failed to Save Patient Address'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Address',
                    message: 'Failed to Save Patient Address'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    };
};

export function emailVerifyPatient(patient) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patient.id + "/ceAdd";
        return put(endpointURL, {})
            .then((response) => {
                if (response.status == "Success") {
                    dispatch({ type: PATIENT_VERIFIED, payload: patient });
                    dispatch(showToast({
                        status: 'success',
                        title: 'Verify',
                        message: 'Patient Verified Successfully'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Verify',
                        message: 'Failed to Verify Patient'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Verify',
                    message: 'Failed to Verify Patient'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
}

export const resetPassword = (patient) => {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patient.id + "/resetpassword";
        post(endpointURL)
            .then((response) => {
                if (response.status == "Success") {
                    dispatch(showToast({
                        status: 'success',
                        title: 'Reset Password',
                        message: "Patient's Password Reset"
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Verify',
                        message: 'Failed to Verify Patient'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })
            .catch((error) => {
                parseJSON(error).then((errorObj) => {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Reset Password',
                        message: errorObj.message
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                })
            })
        dispatch({ type: RESET_PASSWORD_COMPLETE });
    }
}

export function submitGeneralNote(patientId, notes, noteId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        if (noteId) {
            let endpointURL = "/v1/patients/" + patientId + "/notes/" + noteId;
            put(endpointURL, { patient_id: patientId, notes: notes, id: noteId }).
                then((response) => {
                    dispatch({ type: NOTES_RECEIVED, payload: response });
                }).
                catch(error => {
                    dispatch({ type: REQUEST_FAIL });
                    console.log(error);
                    dispatch(showToast({
                        status: 'error',
                        title: 'Notes',
                        message: 'Failed to Update Notes'

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                });
        } else {
            let endpointURL = "/v1/patients/" + patientId + "/notes/";
            post(endpointURL, { patient_id: patientId, notes: notes }).
                then((response) => {
                    dispatch({ type: NOTES_RECEIVED, payload: response });
                }).
                catch(error => {
                    dispatch({ type: REQUEST_FAIL });
                    console.log(error);
                    dispatch(showToast({
                        status: 'error',
                        title: 'Notes',
                        message: 'Failed to Save Notes'

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                });
        }
    }
}

export function fetchGeneralNotes(patientId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURLExist = "/v1/patients/" + patientId + "/notes/exist"
        let endpointURL = "/v1/patients/" + patientId + "/notes/";
        get(endpointURLExist).
            then((response) => {
                if (response) {
                    get(endpointURL).
                        then((response) => {
                            dispatch({ type: NOTES_RECEIVED, payload: response });
                        }).
                        catch((error) => {
                            dispatch({ type: REQUEST_FAIL });
                            console.log(error);
                            dispatch(showToast({
                                status: 'error',
                                title: 'Notes',
                                message: 'Failed to Get Notes'

                            }));
                            let interval = setInterval(() => {
                                clearInterval(interval);
                                dispatch(hideToast());
                            }, 2);
                        })
                } else {
                    dispatch({ type: NOTES_RECEIVED, payload: { notes: null } });
                }
            }).
            catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Notes',
                    message: 'Failed to Get Notes'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
}

export function getLabUnits() {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/labunits";
        get(endpointURL)
            .then((result) => {
                dispatch({ type: LAB_UNITS_RECEIVED, payload: result })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function fetchLabs(patientId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/labs";
        get(endpointURL)
            .then((response) => {
                dispatch({ type: LABS_RECEIVED, payload: response })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function createLab(patientId, data) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/labs";
        post(endpointURL, data)
            .then((response) => {
                get(endpointURL)
                    .then((response) => {
                        dispatch({ type: LABS_RECEIVED, payload: response })
                    })
                    .catch(error => {
                        console.log(error);
                        dispatch({ type: REQUEST_FAIL });
                    });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function deleteLab(patientId, labId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let deleteURL = "/v1/patients/" + patientId + "/labs/" + labId;
        let fetchURL = "/v1/patients/" + patientId + "/labs";
        deleteRequest(deleteURL)
            .then((response) => {
                get(fetchURL)
                    .then((response) => {
                        dispatch({ type: LABS_RECEIVED, payload: response })
                    })
                    .catch(error => {
                        console.log(error);
                        dispatch({ type: REQUEST_FAIL });
                    });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function updateLab(patientId, labId, data) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let updateURL = "/v1/patients/" + patientId + "/labs/" + labId;
        let fetchURL = "/v1/patients/" + patientId + "/labs";
        put(updateURL, data)
            .then((response) => {
                get(fetchURL)
                    .then((response) => {
                        dispatch({ type: LABS_RECEIVED, payload: response })
                    })
                    .catch(error => {
                        console.log(error);
                        dispatch({ type: REQUEST_FAIL });
                    });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function matchRecord(patientId, inboundRecordId, encounterDate) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/matchrecord";
        let inData = {
            'inbound_record_id': inboundRecordId,
            'encounter_date': encounterDate
        }
        put(endpointURL, inData).
            then((response) => {
                if (response.status == 'Success') {
                    dispatch({ type: MEDICAL_RECORD_MATCHED_SUCCESSFULLY, payload: inboundRecordId });
                    dispatch(showToast({
                        status: 'success',
                        title: 'Match',
                        message: 'Record Matched Successfully'

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Match',
                        message: 'Failed to Match Record'

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Match',
                    message: 'Failed to Match Record'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
}

export function fetchAssessments(patientId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/assessments/";
        get(endpointURL).
            then((response) => {
                let dataMap = {};
                _.each(response, (data) => {
                    let dataList = dataMap[data.type];
                    if (!dataList) {
                        dataList = [];
                        dataMap[data.type] = dataList;
                    }
                    dataList.push(data);
                });
                dispatch({ type: ASSESSMENTS_RECEIVED, payload: dataMap });
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Assessments',
                    message: 'Failed to Get Assessments'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function markComplete(patientId, selectedTODOs, selectedValueId, notes) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let allRequest = [];
        _.each(_.keys(selectedTODOs), (todoId) => {
            let endpointURL = "/v1/patients/" + patientId + "/todos/" + todoId + "/complete";
            allRequest.push(post(endpointURL, {
                value_social_determinant_id: selectedValueId ? selectedValueId : null,
                value_social_determinant_note: notes ? notes : null
            }));
        });

        Promise.all(allRequest).
            then((result) => {
                console.log(result);
                dispatch({ type: TODOS_MARK_COMPLETED, payload: result })
            }).
            catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
            })
    }
}

export function addStepForGoal(patientId, goalId, step, stepTitle = "Care Goal") {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/goals/" + goalId + "/steps";
        let data = {
            step: step,
            step_title: stepTitle
        }

        post(endpointURL, data).
            then((result) => {
                dispatch({ type: STEP_ADDED_SUCCESSFULLY, payload: { goalId: goalId, step: result } });
                dispatch(showToast({
                    status: 'success',
                    title: 'Add Care Goal',
                    message: 'Successfully Added Care Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Add Care Goal',
                    message: 'Failed to Add Care Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function markStepComplete(patientId, goalId, stepId, step) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/goals/" + goalId + "/steps/" + stepId;
        let data = {
            id: stepId,
            is_complete: true,
            step: step
        }

        put(endpointURL, data).
            then((result) => {
                dispatch({ type: STEP_UPDATED_SUCCESSFULLY, payload: { goalId: goalId, step: result } });
                dispatch(showToast({
                    status: 'success',
                    title: 'Update Step',
                    message: 'Successfully Completed Step'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Update Step',
                    message: 'Failed to Update Step'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function updateStep(patientId, goalId, stepId, step, stepTitle) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/goals/" + goalId + "/steps/" + stepId;
        let data = {
            id: stepId,
            step: step,
            step_title: stepTitle
        }
        put(endpointURL, data).
            then((result) => {
                dispatch(getGoals(patientId));
                dispatch(showToast({
                    status: 'success',
                    title: 'Update Care Goal',
                    message: 'Successfully Updated Care Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Update Care Goal',
                    message: 'Failed to Update Care Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });

    };
}

export function updateGoal(patientId, goalId, 
        goalTitle,icd10code,goalTypeId,sourceId,
        frequency,otherNote,dueDate) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/goals/" + goalId;
        let data = {
            id: goalId,
            goal: goalTitle,
            icd10code,
            goal_type_id:goalTypeId,
            goal_source_id:sourceId,
            due_date:dueDate
        }

        if(frequency){
            data = {...data,review_frequency_id:frequency,
                frequency_note:otherNote}
        }

        put(endpointURL, data).
            then((result) => {
                dispatch(getGoals(patientId));
                dispatch(showToast({
                    status: 'success',
                    title: 'Update Goal',
                    message: 'Successfully Updated Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Update Goal',
                    message: 'Failed to Update Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function markGoalComplete(patientId, goalId) {
    return (dispatch) => {
        // console.log(patientId, goalId);
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/goals/" + goalId + "/complete"
        post(endpoint).
            then((response) => {
                console.log(response);
                dispatch({ type: GOAL_COMPLETED_SUCCESSFULLY, payload: response });
                dispatch(showToast({
                    status: 'success',
                    title: 'Update Goal',
                    message: 'Successfully Completed Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Update Goal',
                    message: 'Failed to Update Goal'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
}

export function addGoalIntervention(patientId,goalId,
    problem,completionStatusId,action,plan,evaluation){
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/goals/" + goalId + "/steps";
        let data = {
            "step":plan,
            "evaluation":evaluation,
            "step_title": problem,
            "action": action,
            "completion_status_id":completionStatusId
        };

        post(endpoint, data).
            then((response) => {
                dispatch(getGoals(patientId));
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function deleteGoalStep(patientId,goalId,stepId){
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/goals/" + goalId + "/steps/" + stepId;
        

        deleteRequest(endpoint).
            then((response) => {
                dispatch(getGoals(patientId));    
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function updateGoalIntervention(patientId,goalId,stepId,
    problem,completionStatusId,action,plan,evaluation){
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/goals/" + goalId + "/steps/" + stepId;
        let data = {
            "id":stepId,
            "step_title": problem,
            "action": action,
            "step": plan,
            "evaluation": evaluation,
            "completion_status_id":completionStatusId
        };

        put(endpoint, data).
            then((response) => {
               dispatch(getGoals(patientId));
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function addCarePlanAndGoal(patientId, goalTitle,diagonsisCode,
    goal_type_id, goal_source_id, 
    problem = "Care Goal",
    frequency,note,action,plan,evaluation,dueDate) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/goals";
        let data = {
            "goal": goalTitle,
            "icd10code": diagonsisCode, 
            "goal_type_id": goal_type_id,
            "goal_source_id": goal_source_id,
            "review_frequency_id": frequency,
            "frequency_note": note,
            "goal_steps": [
                {
                    "step_title": problem,
                    action,
                    "step":plan,
                    evaluation
                }
            ],
            "due_date":dueDate
        };

        post(endpoint, data).
            then((response) => {
                dispatch(getGoals(patientId));
            }).
            catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export const deleteCareGoal = (patientId, goalId) => {
    return (dispatch) => {
        const endpoint = `/v1/patients/${patientId}/goals/${goalId}`;
        deleteRequest(endpoint)
            .then((response) => {
                dispatch({ type: GOAL_DELETED_SUCCESSFULLY, payload: response });
                dispatch(showToast({
                    status: 'success',
                    title: 'Delete Goal',
                    message: 'Successfully Deleted Goal',

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                dispatch(getGoals(patientId));
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'failure',
                    title: 'Update Goal',
                    message: 'Failed to Delete Goal',

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
};

export function addTODO(patientId, data) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/todos";
        let todoOpenEndpoint = "/v1/patients/" + patientId + "/todos/open";
        post(endpointURL, data).
            then((response) => {
                get(todoOpenEndpoint).
                    then((todoOpenResult) => {
                        let openTodoList = todoOpenResult;
                        openTodoList = _.map(openTodoList, (todo, index) => {
                            return {
                                ...todo,
                                sortIndex: index
                            };
                        });
                        dispatch({ type: TODO_ADDED_SUCCESSFULLY, payload: _.chain(openTodoList).keyBy('id').value() })
                    });
            }).
            catch((error) => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            })
    }
}

export function updateTODO(patientId, todoId, data) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/todos/" + todoId;
        let todoOpenEndpoint = "/v1/patients/" + patientId + "/todos/open";
        put(endpointURL, data).
            then((response) => {
                get(todoOpenEndpoint).
                    then((todoOpenResult) => {
                        let openTodoList = todoOpenResult;
                        openTodoList = _.map(openTodoList, (todo, index) => {
                            return {
                                ...todo,
                                sortIndex: index
                            };
                        });
                        dispatch({ type: TODO_UPDATED_SUCCESSFULLY, payload: _.chain(openTodoList).keyBy('id').value() })
                    });
            }).
            catch((error) => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            })
    }
}

export function closeChart(patientId, note, callLogId, routeTo, userId, nextChart,
                           assistance_type_ids) {
    return (dispatch, getState) => {
        return new Promise((resolve,reject) => {
            try{
                dispatch({ type: REQUEST_SENT });
                let endpointURL = "/v1/patients/" + patientId + "/closechart";
                clearInterval(window.pollingInterval);
                clearInterval(window.secondaryPollingInterval);
                post(endpointURL, { 'notes': note, 'call_log_code_id': callLogId,
                    'assistance_type_ids': assistance_type_ids}).
                    then((response) => {
                        if (response.status == 'Success') {
                            dispatch({ type: CHART_CLOSED_SUCCESSFULLY });
                            dispatch(getUtilization(userId));
                            if (nextChart) {
                                dispatch(pushState(null, `/home/rerouting`));
                                let patientDetailURL = "/v1/patients/" + nextChart;
                                get(patientDetailURL)
                                    .then((result) => {
                                        dispatch(loadPatientDetail(result));
                                        resolve();
                                    })
                                    .catch(error => {
                                        reject(error);
                                        dispatch({ type: REQUEST_FAIL });
                                        const stored_queue = window.localStorage.getItem("ccm_queue");
                                        const queue_user = window.localStorage.getItem("ccm_queue_user");
                                        if (stored_queue && queue_user === getState().admin.activeUser.username) {
                                            dispatch(pushState(null, `/home/${stored_queue}`));
                                        } else {
                                            dispatch(pushState(null, `/home/${getState().queue.currentQueue}`));
                                        }
                                    });
                            } else {
                                resolve();
                                const stored_queue = window.localStorage.getItem("ccm_queue");
                                const queue_user = window.localStorage.getItem("ccm_queue_user");
                                if (stored_queue && queue_user === getState().admin.activeUser.username) {
                                    dispatch(pushState(null, `/home/${stored_queue}`));
                                } else {
                                    dispatch(pushState(null, `/home/${getState().queue.currentQueue}`));
                                }
                            }
                        } else {
                            reject();
                            //reason
                            dispatch({ type: REQUEST_FAIL });
                            dispatch(showToast({
                                status: 'error',
                                title: 'Close Chart',
                                message: response.reason

                            }));
                            let interval = setInterval(() => {
                                clearInterval(interval);
                                dispatch(hideToast());
                            }, 2);
                        }
                    }).
                    catch(error => {
                        reject(error);
                        dispatch({ type: REQUEST_FAIL });
                        dispatch(showToast({
                            status: 'error',
                            title: 'Close Chart',
                            message: 'Failed to Close Chart. Please try again.'

                        }));
                        let interval = setInterval(() => {
                            clearInterval(interval);
                            dispatch(hideToast());
                        }, 2);
                    });
                }catch(error){
                    dispatch({ type: REQUEST_FAIL });
                        dispatch(showToast({
                            status: 'error',
                            title: 'Close Chart',
                            message: 'Failed to Close Chart. Please try again.'

                        }));
                        let interval = setInterval(() => {
                            clearInterval(interval);
                            dispatch(hideToast());
                        }, 2);
                }
            
            });
    }
}

export function getActiveUser(userId) {
    return (dispatch) =>
        get(`/v1/mas/${userId}`)
            .then((result) => {
                dispatch({ type: ACTIVE_USER_RECEIVED, payload: result });
                dispatch(getTeamsIfNeeded(result));
            })
            .catch(() => false);
}

function getTeamsIfNeeded(activeUser){
    return (dispatch,getState) => {
        const user = getState().auth;
        const queue = getState().queue.queue;
        const isUserAdmin = isAdmin(user.roles);
        const isMutiteamUserRole = isMutiTeamUser(user.roles);
        const isTriageUser = isTriage(user.roles);
        const isMAUser = isMA(user.roles);
            
        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
        const teamId = activeUser && activeUser.team_id;
        let ce_flag, isRetSpec;
        if (activeUser && activeUser.center && activeUser.center.company_id) {
            ce_flag = activeUser.center.ce_flag;
            isRetSpec = activeUser.center.support_flag;
        }
        if(!isGAPS && !isRPM && !ce_flag  && !isRetSpec){
            if(isUserAdmin || isMutiteamUserRole || isTriageUser || isMAUser){
                dispatch(getTeams(activeUser.id))
                if(teamId && teamId != 0){
                    dispatch(getClinicians(activeUser.id,
                        teamId));
                }
            }else{
                if(teamId && teamId != 0){
                    dispatch(getClinicians(activeUser.id,
                        teamId));
                }
                
            }
        }
    }
           
}

export function getTeams(userId) {
    return (dispatch) =>
        get(`/v1/teams`)
            .then((result) => {
                dispatch({ type: ON_TEAMS_RECEVIED, payload: result });
            })
            .catch(() => false);
}


export function getTeamsUnAssignedPatients(teamId,orgId) {
    return new Promise((resolve,reject) => {
        let endpoint = `/v1/teams/${teamId}/unassignedPatients/_count`;
        if(orgId){
            endpoint = `/v1/teams/${teamId}/organizations/${orgId}/unassignedPatients/_count`;
        }
        get(endpoint)
            .then((result) => {
                resolve(result);
            })
            .catch(() => { 
                reject(); 
                return false});
        }
    )
}

export function assignedPatients(userId,teamId,clinicianId, noOfPatients,
    selectedOrgId){
        return new Promise((resolve,reject) => {
            let endpoint = `/v1/teams/${teamId}/clinicians/${clinicianId}/assignPatients?numberOfPatientsToAssign=${noOfPatients}`
            if(selectedOrgId){
                endpoint = `/v1/teams/${teamId}/organizations/${selectedOrgId}/clinicians/${clinicianId}/assignPatients?numberOfPatientsToAssign=${noOfPatients}`
            }
            put(endpoint,{})
            .then((result) => {
                resolve(result);
            })
            .catch(() => {
                reject()
                return false
            });
        })
}

export function getCliniciansByAsync(teamId,selectedOrgId) {
    return  new Promise((resolve,reject) => {
        if(teamId){
            let endpoint = `/v1/teams/${teamId}/clinicianDetails`;
            if(selectedOrgId){
                endpoint = `/v1/teams/${teamId}/organizations/${selectedOrgId}/clinicianDetails`;
            }
            get(endpoint)
            .then((result) => {
                const listData = _.map(result,(data) => {
                    return {...data,id:data.ccm_user_id}
                })
                resolve(listData);
            })
            .catch(() => {
                reject();
                return false
            });
        }else{
            resolve([]);
        }
       
  })
}

export function getOrganizationByTeam(teamId) {
    return  new Promise((resolve,reject) => {
        if(teamId){
            get(`/v1/teams/${teamId}/organizations`)
            .then((result) => {
                const listData = _.map(result,(data) => {
                    return {...data}
                })
                resolve(listData);
            })
            .catch(() => {
                reject();
                return false
            });
        }else{
            resolve([]);
        }
       
  })
}

export function getClinicians(userId,teamId) {
    return (dispatch) =>
        new Promise((resolve,reject) => {
            if(teamId){
                get(`/v1/teams/${teamId}/clinicians`)
                .then((result) => {
                    const listData = _.map(result,(data) => {
                        return {...data,id:data.ccm_user_id}
                    })
                    const options = [{id:0,name:'Unassigned'},...listData];
                    dispatch({ type: ON_CLINICIANS_RECEIVED, payload: options });
                    resolve(options);
                })
                .catch(() => {
                    reject();
                    return false
                });
            }else{
                dispatch({ type: ON_CLINICIANS_RECEIVED, payload: [] });
                resolve([]);
            }
           
      })
}

export function getAllClinicians(userId,teamId) {
    return (dispatch) =>
        new Promise((resolve,reject) => {
            if(teamId){
                get(`/v1/clinicians`)
                .then((result) => {
                    const listData = _.map(result,(data) => {
                        return {...data,id:data.ccm_user_id}
                    })
                    const options = [{id:0,name:'Unassigned'},...listData];
                    dispatch({ type: ON_ALL_CLINICIANS_RECEIVED, payload: options });
                    resolve(options);
                })
                .catch(() => {
                    reject();
                    return false
                });
            }else{
                dispatch({ type: ON_CLINICIANS_RECEIVED, payload: [] });
                resolve([]);
            }
           
      })
}
export function getCompanies() {
    return (dispatch) => {
        let endpoint = "/v1/companies";
        get(endpoint).
            then((result) => {
                dispatch({ type: COMPANIES_RECEIVED, payload: result })
            }).
            catch((error) => {
                console.log(error);
            });
    }
}

export function fetchOrganizations() {
    return (dispatch) =>
        get('/v1/filteredorganizations')
            .then((response) => {
                dispatch({ type: FETCH_ORGS_SUCCESS, payload: response });
            })
            .catch(() => false);
}

export function fetchLocations(orgId) {
    return (dispatch) =>
        get(`/v1/organizations/${orgId}/aplenty`)
            .then((response) => {
                dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: response });
            })
            .catch(() => false);
}

export function fetchCaseHistory(patientId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/casehistory";
        get(endpoint).
            then((result) => {
                dispatch({ type: CASE_HISTORY_RECEIVED, payload: result });
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Case History',
                    message: "Error to get Case History"

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function getSSODetail(id) {
    return (dispatch) => {
        let ssoNodeURL = "/v1/sso/chartspan/" + id;
        post(ssoNodeURL, {})
            .then((result) => {
                if (result.status == 'Success') {
                    dispatch({
                        type: SSO_TOKEN_RECEIVED,
                        payload: {
                            ssoToken: result.CSToken
                        }
                    });
                } else {
                    dispatch(showToast({
                        status: 'error',
                        title: 'SSO Error',
                        message: result.reason

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            });
    }
}

export function getDetail(data, showMask) {
    return (dispatch,getState) => {
        const authData = getState().auth;
        const isUserAdmin = isAdmin(authData.roles);
        const isTriageUser = isTriage(authData.roles);
        if (showMask) {
            dispatch({ type: REQUEST_SENT });
        }
        dispatch(openEventStream(data.id, 'close_chart_requests'));
        let todoCompletedEndpoint = "/v1/patients/" + data.id + "/todos/completed";
        let todoOpenEndpoint = "/v1/patients/" + data.id + "/todos/open";
        get(todoCompletedEndpoint).
            then((todoResult) => {
                let completedTODOList = todoResult;
                get(todoOpenEndpoint).
                    then((todoOpenResult) => {
                        let openTodoList = todoOpenResult;
                        completedTODOList = _.map(completedTODOList, (todo, index) => {
                            return {
                                ...todo,
                                sortIndex: index
                            };
                        });
                        openTodoList = _.map(openTodoList, (todo, index) => {
                            return {
                                ...todo,
                                sortIndex: index
                            };
                        });
                        if(!isUserAdmin && !isTriageUser){
                            completedTODOList = _.filter(completedTODOList,(data) => {
                                return data.urgent_todo_type_id != 24;
                            })
                            openTodoList = _.filter(openTodoList,(data) => {
                                return data.urgent_todo_type_id != 24;
                            })
                        }
                        dispatch({
                            type: LEVEL_TWO_DASHBOARD_DATA_RECEIVED,
                            payload: {
                                todoLists: _.chain(openTodoList).keyBy('id').value(),
                                completedTODOList: _.chain(completedTODOList).keyBy('id').value()
                            }
                        });

                        dispatch({ type: PATIENT_DETAIL_RECEIVED, payload: data });
                        dispatch(pushState(null, "/home/patient/" + data.id));
                    })
            })
    }
}

export function loadPatientDetail(data) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });

        let openChartEndPoint = "/v1/patients/" + data.id + "/openchart";
        post(openChartEndPoint, {}).
            then((openChartResponse) => {
                if (openChartResponse.status == 'Success') {
                    dispatch(getDetail(data));
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Open Chart',
                        message: openChartResponse.reason

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Open Chart',
                    message: 'Chart is currently opened by another agent'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 4);
            });

    }
}

//export function getPatientDetail(patientId) {
//    return (dispatch)=> {
//        dispatch({type: REQUEST_SENT});
//
//        let todoList = [
//            {
//                todoDate: '12/12/2016',
//                todoLabel: 'asbas asbasasas asas asas asas asasas as as a s as as a sasasasas',
//                completed: false,
//                id:1
//            },
//            {
//                todoDate: '12/12/2016',
//                todoLabel: 'asbas asbasasas asas asas asas asasas as as a s as as a sasasasas',
//                completed: false,
//                id:2
//            },
//            {
//                todoDate: '12/12/2016',
//                todoLabel: 'asbas asbasasas asas asas asas asasas as as a s as as a sasasasas',
//                completed: false,
//                id:3
//            },
//            {
//                todoDate: '12/12/2016',
//                todoLabel: 'asbas asbasasas asas asas asas asasas as as a s as as a sasasasas',
//                completed: false,
//                id:4
//            }];
//
//        dispatch({type:LEVEL_TWO_DASHBOARD_DATA_RECEIVED,payload:{
//            todoLists:todoList
//        }});
//    }
//}


export function assignMA(patientId, maID) {
    return (dispatch) => {

        dispatch({ type: REQUEST_SENT });
        console.log(patientId + " - " + maID);
        let data = { "assigned_to_id": maID };
        let nodeURL = "/v1/patient/" + patientId + "/assignment";
        post(nodeURL, data)
            .then((result) => {
                console.log(result);
                dispatch({ type: MA_ASSIGNED_SUCCESSFULLY, payload: patientId });
                dispatch(showToast({
                    status: 'success',
                    title: 'MA Assignment',
                    message: 'Medical Assistant Assigned Successfully'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getChartOpenBy(patientId) {
    return (dispatch) => {
        dispatch({ type: CHART_OPEN_BY_REQUEST });
        return get(`/v1/patients/${patientId}/chartopenby`)
            .then((response) => {
                dispatch({ type: CHART_OPEN_BY_REQUEST_SUCCESS });
                return response;
            })
            .catch(() => {
                dispatch({ type: CHART_OPEN_BY_REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Unable To Retrieve Name of Chart Opener'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                throw Error;
            });
    };
}

export function checkChartOpenBy(patientId) {
    return (dispatch) => {
        dispatch({ type: CHART_OPEN_BY_REQUEST });
        return get(`/v1/patients/${patientId}/chartopenby`)
            .then((response) => {
                dispatch({ type: CHART_OPEN_BY_REQUEST_SUCCESS });
                return response;
            })
            .catch(() => {
                dispatch({ type: CHART_OPEN_BY_REQUEST_FAIL });
                throw Error;
            });
    };
}

export function unenrollPatient(patientId, notes, reason) {
    return (dispatch) => {
        dispatch({ type: INITIATE_UNENROLL_PATIENT_REQUEST });

        let data = { "notes": notes, unenroll_reason_id: reason },
            nodeURL = `/v1/patients/${patientId}/unenrollrequest`;

        post(nodeURL, data).
            then((result) => {
                dispatch({ type: INITIATE_UNENROLL_PATIENT_SUCCESS, payload: result });

                dispatch(showToast({
                    status: 'success',
                    title: 'Patient Unenrolled',
                    message: 'Patient Unenrolled Successfully'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).catch(error => {
                console.log(error);
                dispatch({ type: INITIATE_UNENROLL_PATIENT_FAIL });
            });
    }
}

export const changeStatusToSupport = (patientId, support_reason_id, support_note) => {
    return (dispatch) => {
        const endpoint = `/v1/patients/${patientId}/support`;
        const body = { support_reason_id, support_note };
        put(endpoint, body)
            .then((result) => {
                dispatch({ type: SEND_TO_SUPPORT_SUCCESS, payload: result });

                dispatch(showToast({
                    status: 'success',
                    title: 'Patient Status Changed',
                    message: 'Patient Successfully Sent to Support'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Failed',
                    message: 'Unable To Send Patient to Patient Services'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                dispatch({ type: SEND_TO_SUPPORT_FAILED })
            });
    }
};

export function getOpenChartsDetails(callback) {
    return (dispatch) => {
        dispatch({ type: GET_OPEN_CHARTS_REQUEST });

        let endpoint = '/v1/supervisors/mas/opencharts';
        get(endpoint).
            then(result => {
                dispatch({
                    type: GET_OPEN_CHARTS_SUCCESS,
                    payload: result
                });

                if (callback) {
                    callback();
                }
            }).
            catch(error => {
                dispatch({ type: GET_OPEN_CHARTS_FAIL });

                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Unable To Retrieve List of Open Charts'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function closeChartByAdmin(maId, note, patient_id) {
    return (dispatch) => {
        dispatch({ type: ADMIN_CLOSE_CHART_REQUEST });

        let data = {
            "notes": note
        },
            nodeURL = `/v1/supervisors/${maId}/closechart`;

        post(nodeURL, data)
            .then((result) => {
                dispatch(checkIfChartClosed(patient_id));
            })
            .catch((error) => {
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Chart auto-closure failed',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function getCCDAAvailability(patientId) {
    return (dispatch) => {
        dispatch({ type: GET_CCDA_AVAILABILITY_REQUEST });
        return get(`/v1/patients/${patientId}/ccdameta`)
            .then((response) => {
                dispatch({
                    type: GET_CCDA_AVAILABILITY_SUCCESS,
                    payload: response,
                });
            })
            .catch(() => {
                dispatch({ type: GET_CCDA_AVAILABILITY_FAIL });
            });
    }
}

export function getCCDAPdf(patientId) {
    return (dispatch) => {
        dispatch({ type: GET_CCDA_PDF_REQUEST });
        return get(`/v1/patients/${patientId}/ccda`, {
            'Accept': 'application/pdf',
            'Content-Type': 'application/pdf'
        },
            true)
            .then((response) => {
                dispatch({
                    type: GET_CCDA_PDF_SUCCESS,
                    payload: window.URL.createObjectURL(response),
                });
            })
            .catch(() => {
                dispatch({ type: GET_CCDA_PDF_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Couldn\'t Fetch CCDA PDF'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                throw Error;
            });
    }
}

export function getMedSummaryAvailability(patientId) {
    return (dispatch) =>
        get(`/v1/patients/${patientId}/medsummarymeta`)
            .then((response) => {
                dispatch({
                    type: GET_MED_SUMMARY_AVAILABILITY_SUCCESS,
                    payload: response,
                });
            })
            .catch(() => {
                dispatch({ type: GET_MED_SUMMARY_AVAILABILITY_FAILURE });
            });
}

export function getMedSummaryPdf(patientId) {
    return (dispatch) => {
        dispatch({ type: GET_MED_SUMMARY_PDF_REQUEST });
        return get(`/v1/patients/${patientId}/medsummary`)
            .then((response) => {
                dispatch({
                    type: GET_MED_SUMMARY_PDF_SUCCESS,
                    payload: response.link,
                });
            })
            .catch(() => {
                dispatch({ type: GET_MED_SUMMARY_PDF_FAILURE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Couldn\'t Fetch Medical Summary'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                throw Error;
            });
    }
}

export function getPHSAvailability(patientId, callback) {
    return (dispatch) => {
        dispatch({ type: GET_PHS_AVAILABILITY_REQUEST });

        let endpoint = `/v1/patients/${patientId}/ccpmeta`;
        get(endpoint).
            then(result => {
                dispatch({
                    type: GET_PHS_AVAILABILITY_SUCCESS,
                    payload: result
                });

                if (callback) {
                    callback();
                }
            }).
            catch(error => {
                dispatch({ type: GET_PHS_AVAILABILITY_FAIL });

                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Couldn\'t Fetch Details About PHS Availability'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);

                if (callback) {
                    callback(true, error);
                }

                console.log('Error while fetching details about PHS availability - ', error);
            });
    }
}

export function getPHSPdf(patientId, callback) {
    return (dispatch) => {
        dispatch({ type: GET_PHS_PDF_REQUEST });

        let endpoint = `/v1/patients/${patientId}/ccp`;
        get(endpoint).
            then(result => {
                if (result.exists) {
                    dispatch({
                        type: GET_PHS_PDF_SUCCESS,
                        payload: result.link
                    });
                } else {
                    dispatch(showToast({
                        status: 'error',
                        title: 'Request Failed',
                        message: 'Couldn\'t Fetch PHS PDF'

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }

                if (callback) {
                    callback();
                }
            }).
            catch(error => {
                dispatch({ type: GET_PHS_PDF_FAIL });

                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Couldn\'t Fetch PHS PDF'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);

                if (callback) {
                    callback(true, error);
                }

                console.log('Error while fetching PHS PDF - ', error);
            });
    }
}

export function updatePatientDetails(patientId, patientDetails) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });

        let updatePatientInfoURL = "/v1/patients/" + patientId;
        put(updatePatientInfoURL, patientDetails)
            .then((patientInfoUpdateResponse) => {
                if (patientInfoUpdateResponse.status == 'Success') {
                    get(updatePatientInfoURL).
                        then((patientDetailsResponse) => {
                            console.log('patientDetailsResponse >>> ', patientDetailsResponse);
                            dispatch({ type: PATIENT_DETAILS_UPDATED, payload: patientDetailsResponse });
                        }).
                        catch(error => {
                            dispatch({ type: REQUEST_FAIL });
                        })
                } else {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Update Patient Details',
                        message: patientInfoUpdateResponse.message

                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })

            .catch((error) => {
                parseJSON(error).then((errorObj) => {
                    dispatch({ type: REQUEST_FAIL });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Update Patient Details',
                        message: errorObj.message
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                })
            });

    }
}

export function resetUpdatedPatientDetailsStatus() {
    return function (dispatch) {
        dispatch({ type: RESET_PATIENT_UPDATE_STATUS });
    }
}

export function closeChartSync(patientId, note, callback) {
    let endpointURL = "/v1/patients/" + patientId + "/closechart";
    let loggedinUser = localStorage.getItem('ccmLoggedinUser');

    if (loggedinUser !== null) {
        let loggedinUserObj = JSON.parse(loggedinUser);
        $.ajax({
            type: 'POST',
            async: false,
            data: JSON.stringify({ 'notes': note }),
            url: config.BASE_URL + endpointURL,
            beforeSend: (request) => {
                request.setRequestHeader("Accept", 'application/json');
                request.setRequestHeader("Content-Type", 'application/json');
                request.setRequestHeader("X-AUTH-TOKEN", loggedinUserObj.token);
            },
            success: () => {
                callback && callback();
            }
        });
    }
}

export function getAssessmentQuestions(pageName) {
    return (disptach) => {
        disptach({ type: REQUEST_SENT });
        let endpointURL = `/v1/dynamic/${pageName}`;
        get(endpointURL).
            then((response) => {
                disptach({ type: ASSESSMENT_QUESTIONS_RECEIVED, payload: response });
            }).
            catch(error => {
                console.log(error);
            });
    }
}

export function getAssessmentAnswers(patientId, pageName) {
    return (disptach) => {
        disptach({ type: REQUEST_SENT });
        let endpointURL = `/v1/dynamic/${pageName}/patient/` + patientId;
        get(endpointURL).
            then((response) => {
                disptach({ type: ASSESSMENT_ANSWERS_RECEIVED, payload: response });
            }).
            catch(error => {
                console.log(error);
            });
    }
}


export function getGAPSAssessmentAnswers(gapsCarePlanId) {
    return (disptach) => {
        disptach({ type: REQUEST_SENT });
        let endpointURL = `/v1/gaps/careplananswers?gapsCarePlanId=${gapsCarePlanId}`
        get(endpointURL).
        then((response) => {
            disptach({ type: GAPS_ANSWERS_RECEIVED, payload: response });
            disptach({ type: MAKE_CALL_SUCCESS });
        }).
        catch(error => {
            console.log(error);
            disptach({ type: REQUEST_FAIL });
        });
    }
}

export function saveAssessmentAnswers(patientId, data, pageName) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let title = "CCM Assessment Saved";
        let message = "CCM Assessment updates have been saved.";
        if (pageName == 'mipsassessments') {
            title = "MIPS Assessment Saved";
            message = "MIPS Assessment updates have been saved.";
        }
        const endpointURL = `/v1/dynamic/${pageName}/patient/${patientId}`;
        post(endpointURL, data).
            then((result) => {
                dispatch({ type: ASSESSMENT_ANSWERS_RECEIVED, payload: result });
                dispatch(showToast({
                    status: 'success',
                    title: title,
                    message: message
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }).
            catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
            });
    }
}

export const saveGAPSAssessmentAnswer = (carePlanId, data, pageId) => dispatch => {

    dispatch({ type: REQUEST_SENT });
    const endpointURL = `/v1/gaps/${carePlanId}/page/${pageId}/careplananswers`;
    post(endpointURL, data).
    then((result) => {
        dispatch({ type: GAPS_ANSWERS_RECEIVED, payload: result });
        dispatch(showToast({
            status: 'success',
            title: 'GAPS Assessment Saved',
            message: 'GAPS Assessment updates have been saved.'
        }));
        const interval = setInterval(() => {
            clearInterval(interval);
            dispatch(hideToast());
        }, 2);
    }).
    catch((error) => {
        dispatch({ type: REQUEST_FAIL });
        console.log(error);
    });
}

export const getGAPSEngagements = (carePlanId) => dispatch => {
    return new Promise((resolve,reject) => {
        get(`/v1/gaps/careplan/${carePlanId}/engagements`)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            })
    })
}

export const getGAPSHistory = (patientId) => dispatch => {
    get(`/v1/gaps/patient/${patientId}/reporting_history`)
        .then(result => {
            dispatch({ type: GAPS_HISTORY_RECEIVED, payload: result });
        })
        .catch(error => {
            dispatch({ type: GAPS_HISTORY_RECEIVED, payload: [] });
        })
}
export const saveGAPSEngagement = (carePlanId, data, pageId,engagement_type,
                                   gaps_care_plan_status_id,note,
                                   engagement_attrs,answeredSaved,patientId) => dispatch => {

    dispatch({ type: REQUEST_SENT });
    const engagementPayload = {
        engagement_type,
        gaps_care_plan_status_id,
        note,
        engagement_attrs
    };
    if(answeredSaved){
        const engagementEndPoint = `/v1/gaps/careplan/${carePlanId}/engagement`
        post(engagementEndPoint,engagementPayload)
            .then((engagementResult) => {
                dispatch(showToast({
                    status: 'success',
                    title: 'GAPS Assessment Saved',
                    message: 'GAPS Assessment updates have been saved.'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                dispatch(getCarePlans(patientId))
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
            });
    }else{
        const endpointURL = `/v1/gaps/${carePlanId}/page/${pageId}/careplananswers`;
        post(endpointURL, data).
        then((result) => {
            const engagementEndPoint = `/v1/gaps/careplan/${carePlanId}/engagement`
            post(engagementEndPoint,engagementPayload)
                .then((engagementResult) => {
                    dispatch({ type: GAPS_ANSWERS_RECEIVED, payload: result });
                    dispatch(showToast({
                        status: 'success',
                        title: 'GAPS Assessment Saved',
                        message: 'GAPS Assessment updates have been saved.'
                    }));
                    const interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                    dispatch(getCarePlans(patientId))
                })
                .catch((error) => {
                    dispatch({ type: REQUEST_FAIL });
                    console.log(error);
                });

        }).
        catch((error) => {
            dispatch({ type: REQUEST_FAIL });
            console.log(error);
        });
    }


}

export function updateAssessmentAnswers(data) {
    return (dispatch) => {
        dispatch({ type: UPDATE_ASSESSMENT_ANSWERS, payload: data })
    }
}


export function updateGAPSAssessmentAnswers(data) {
    return (dispatch) => {
        dispatch({ type: UPDATE_GAPS_ASSESSMENT_ANSWERS, payload: data })
    }
}


export function updateAssessmentUpdates(data) {
    return (dispatch) => {
        dispatch({ type: UPDATE_ASSESSMENT_UPDATES, payload: data })
    }
}

export const invalidRequest = (errors) => {
    return (dispatch) => {
        if (typeof errors !== 'undefined') {
            dispatch({ type: REQUEST_RECORD_INVALID, payload: errors });
        }
    }
}

export const validRequest = (formData) => {
    return (dispatch) => {
        dispatch({ type: REQUEST_RECORD_VALID, payload: formData });
    }
}

export const submitRequestRecord = (formData, selectedPatient, providerId) => {
    return (dispatch) => {
        let patientId = selectedPatient.id;
        let endpoint = '/v1/patients/' + patientId + '/recordrequests';
        let payload = {
            specialist_id: providerId,
            request_start: moment(formData.dateRangeBegin, 'MM-DD-YYYY').format('YYYY-MM-DD'),
            request_end: moment(formData.dateRangeEnd, 'MM-DD-YYYY').format('YYYY-MM-DD')
        }
        post(endpoint, payload)
            .then((result) => {
                let requestEntry = {
                    clinic: formData.clinic,
                    name: formData.doctorName,
                    create_ts: new Date(),
                    request_start: payload.request_start,
                    request_end: payload.request_end,
                    status: 'PENDING'
                }
                dispatch({ type: SUBMIT_REQUEST_VALID, payload: requestEntry });
                if (result.status !== 'ERROR') {
                    dispatch(showToast({
                        status: 'success',
                        title: 'Submit Request',
                        message: 'Your request for a record has been successfully submitted.'

                    }));
                } else {
                    dispatch(showToast({
                        status: 'error',
                        title: 'Submit Request',
                        message: result.status_note
                    }));
                }
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                console.log(error);
                const message = error.message === 'Bad Request' ? `Cannot request a record from ${selectedPatient.provider.organization}.` : 'Request failed.'
                dispatch(showToast({
                    status: 'error',
                    title: 'Submit Request',
                    message,
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export const fetchRequestHistory = (selectedPatient) => {
    return (dispatch) => {
        let patientId = selectedPatient.id;
        let endpoint = '/v1/patients/' + patientId + '/recordrequests';
        get(endpoint)
            .then((result) => {
                dispatch({ type: RECEIVED_REQUEST_HISTORY, payload: result });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export const fetchProviderList = (selectedPatient) => {
    return (dispatch) => {
        let patientId = selectedPatient.id;
        let endpoint = '/v1/patients/' + patientId + '/specialists';
        get(endpoint)
            .then((result) => {
                dispatch({ type: RECEIVED_PROVIDER_LIST, payload: result });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export const getSentRecords = (patientId) => {
    return (dispatch) => new Promise((resolve, reject) => {
        let endpoint = '/v1/patients/' + patientId + '/sentrecordrequests';
        get(endpoint)
            .then((result) => {
                let payload = {
                    sentRecords: result,
                    patientId
                }
                dispatch({ type: SENT_RECORD_REQUESTS_SUCCESS, payload });
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    })
}

export const matchSentRecord = (patientId, formData, inboundRecordId, hasRecord, flip) => {
    return (dispatch) => new Promise((resolve, reject) => {
        let endpoint = '/v1/patients/' + patientId + '/matchrecord';
        let basePayload = {
            record_request_ids: formData.record_request_ids,
            inbound_record_id: inboundRecordId,
            flip,
        };
        let payload;
        if (hasRecord) {
            payload = Object.assign({}, basePayload, { encounter_date: moment(formData.encounter_date).format('YYYY-MM-DD') })
        } else {
            payload = Object.assign({}, basePayload, { no_records: true })
        }
        put(endpoint, payload)
            .then(() => {
                dispatch(showToast({
                    status: 'success',
                    title: 'Match Record',
                    message: 'Record successfully matched.'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                return Promise.all([
                    dispatch(getInboundMedicalRecords()),
                    dispatch(getSentRecords(patientId)),
                ])
            })
            .then(() => {
                resolve({ ok: true });
            })
            .catch((error) => {
                console.log(error);

                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Match Record',
                    message: 'Match record unsuccessful.'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                resolve({ ok: false });
            });
    })
}

export const clearMedicalForms = (patientId) => {
    return (dispatch) => {
        dispatch({ type: CLEAR_MEDICAL_FORM_DOC })
    }
}

export const mergeAccounts = (patientId, newEmailAddress) => {
    return (dispatch) => {
        let endpoint = '/v1/sso/chartspan/' + patientId + '/merge?base_email=' + newEmailAddress;
        post(endpoint)
            .then((result) => {
                dispatch(showToast({
                    status: 'success',
                    title: 'Merge Accounts',
                    message: 'Accounts successfully merged.'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Merge Accounts',
                    message: 'Account merge unsucessful.'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
}

export const employeeChartTime = (formData, reportToken) => {
    return (dispatch) => {
        let startDate = formData.start_range.split('-');
        let endDate = formData.end_range.split('-');
        let startMonth = startDate[0];
        let startYear = startDate[1];
        let endMonth = endDate[0];
        let endYear = endDate[1];
        let endpoint = config.REPORTS_URL + 'report/json/?report=ccm%2Femployee-chart-time.sql&macros%5Brange%5D%5Bstart%5D=' + startYear + '-' + startMonth + '-01+00%3A00%3A00&macros%5Brange%5D%5Bend%5D=' + endYear + '-' + endMonth + '-01+23%3A59%3A59&token=' + reportToken;
        dispatch({ type: REQUEST_SENT });
        $.ajax({
            url: endpoint,
            type: 'GET',
            dataType: 'json',
            success: (response) => {
                dispatch({ type: RECEIVED_REPORTING_DATA, payload: response })
                dispatch({ type: REQUEST_COMPLETE });
            },
            fail: (error) => {
                console.log(error);
            }
        });
    }
}

export const patientsNeedingWork = (reportToken) => {
    return (dispatch) => {
        let endpoint = config.REPORTS_URL + 'report/json/?report=ccm/patients-needing-work.sql&token=' + reportToken;
        dispatch({ type: REQUEST_SENT });
        $.ajax({
            url: endpoint,
            type: 'GET',
            dataType: 'json',
            success: (response) => {
                dispatch({ type: RECEIVED_REPORTING_DATA, payload: response })
                dispatch({ type: REQUEST_COMPLETE });
            },
            fail: (error) => {
                console.log(error);
            }
        });
    }
}

export const chartByEmployee = (formData, reportToken) => {
    return (dispatch) => {
        let endpoint = config.REPORTS_URL + 'report/json/?report=ccm%2Fcharts-by-employee.sql&macros%5Bccm_user_id%5D=' + formData.employee_id + '&token=' + reportToken;;
        dispatch({ type: REQUEST_SENT });
        $.ajax({
            url: endpoint,
            type: 'GET',
            dataType: 'json',
            success: (response) => {
                dispatch({ type: RECEIVED_REPORTING_DATA, payload: response })
                dispatch({ type: REQUEST_COMPLETE });
            },
            fail: (error) => {
                console.log(error);
            }
        });
    }
}

export const patientTracking = (reportToken) => {
    return (dispatch) => {
        let endpoint = config.REPORTS_URL + 'report/json/?report=ccm/patient-tracking-report.sql&datasets=all&token=' + reportToken;
        dispatch({ type: REQUEST_SENT });
        $.ajax({
            url: endpoint,
            type: 'GET',
            dataType: 'json',
            success: (response) => {
                dispatch({ type: RECEIVED_REPORTING_DATA, payload: response })
                dispatch({ type: REQUEST_COMPLETE });
            },
            fail: (error) => {
                console.log(error);
            }
        });
    }
}

export const getReportsToken = (report, formData) => {
    return (dispatch) => {
        let endpoint = config.BASE_URL + '/v1/auth/reportToken';
        let loggedinUser = localStorage.getItem('ccmLoggedinUser');
        let loggedinUserObj = loggedinUser && JSON.parse(loggedinUser);
        $.ajax({
            url: endpoint,
            type: 'GET',
            dataType: 'json',
            beforeSend: (xhr) => xhr.setRequestHeader('X-AUTH-TOKEN', loggedinUserObj.token),
            success: (response) => {
                switch (report) {
                    case 'chartByEmployee':
                        response.token && dispatch(chartByEmployee(formData, response.token));
                        break;
                    case 'patientTracking':
                        response.token && dispatch(patientTracking(response.token));
                        break;
                    case 'patientsNeedingWork':
                        response.token && dispatch(patientsNeedingWork(response.token));
                        break;
                    case 'employeeChartTime':
                        response.token && dispatch(employeeChartTime(formData, response.token));
                        break;
                    default:
                        response.token && localStorage.setItem('reportToken', response.token);
                }
            },
            fail: (error) => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Report authentication',
                    message: 'Authentication failed.'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 3);
            }
        });
    }
}

export const sortReportData = (sortType, key) => {
    return (dispatch) => {
        switch (sortType) {
            case 'ascending':
                dispatch({ type: SORT_REPORTS_ASCENDING, payload: key });
                break;
            case 'descending':
                dispatch({ type: SORT_REPORTS_DESCENDING, payload: key });
                break;
            default:
                break;
        }
    }
}

export function getCallLogSelections() {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/codes/calllogs";
        get(endpointURL)
            .then((result) => {
                dispatch({ type: CALL_LOG_SELECTIONS_RECEIVED, payload: result })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}


export function getCallAssistanceTypes() {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/assistancetypes";
        get(endpointURL)
            .then((result) => {
                dispatch({ type: CALL_ASSISTANCE_TYPES, payload: result })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export const rejectMatchRecord = (patientId, matchId, approvalQueueRecord) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    let endpointURL = "/v2/patients/" + patientId + "/rejectmatch/" + matchId;
    put(endpointURL)
        .then((result) => {
            dispatch(showToast({
                status: 'success',
                title: 'Record Rejected',
                message: 'Record successfully rejected.'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch(getNextFaxRecordForApproval(approvalQueueRecord));
        })
        .catch((error) => {
            parseJSON(error).then((errorObj) => {
                const message = errorObj.message || 'Unable to match record';
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message,
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                dispatch({ type: REQUEST_FAIL });
            });
        });
}

export const approveMatchRecord = (patientId, matchId, approvalQueueRecord) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    let endpointURL = "/v2/patients/" + patientId + "/approvematch/" + matchId;
    put(endpointURL)
        .then((result) => {
            dispatch(showToast({
                status: 'success',
                title: 'Record Matched',
                message: 'Record successfully matched.'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch(getNextFaxRecordForApproval(approvalQueueRecord));
        })
        .catch((error) => {
            parseJSON(error).then((errorObj) => {
                const message = errorObj.message || 'Unable to match record';
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message,
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                dispatch({ type: REQUEST_FAIL });
            });
        });
}

export function confirmFaxMatch(patientId, matchId) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/approvematch/" + matchId;
        put(endpointURL)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: CLEAR_MEDICAL_FORM_DOC });
                dispatch({ type: CLEAR_MATCH_APPROVAL_PATIENT });
                dispatch(getInboundMatchedMedicalRecords());
                dispatch(showToast({
                    status: 'success',
                    title: 'Record Matched',
                    message: 'Record successfully matched.'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                return dispatch(getInboundMatchedMedicalRecords());
            }).then(() => {
                resolve({ ok: true })
            })
            .catch((error) => {
                parseJSON(error).then((errorObj) => {
                    const message = errorObj.message || 'Unable to match record';
                    dispatch(showToast({
                        status: 'error',
                        title: 'Error',
                        message,
                    }));
                    const interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                    dispatch({ type: REQUEST_FAIL });
                });
                resolve({ ok: false })
            });
    })
}

export function rejectFaxMatch(patientId, matchId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpointURL = "/v1/patients/" + patientId + "/rejectmatch/" + matchId;
        put(endpointURL)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: CLEAR_MEDICAL_FORM_DOC });
                dispatch({ type: CLEAR_MATCH_APPROVAL_PATIENT });
                dispatch(getInboundMatchedMedicalRecords());
                dispatch(showToast({
                    status: 'success',
                    title: 'Record Rejected',
                    message: 'Record successfully rejected.'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getPatientCcmProiders(patientId) {
    return (dispatch) => {
        let nodeURL = "/v1/patients/" + patientId + "/ccmproviders";
        get(nodeURL)
            .then((result) => {
                dispatch({ type: PATIENT_CCM_PROVIDERS_RECEIVED, payload: result })
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function updatePatientCcmProider(patientId, providerId) {
    return (dispatch) => {
        let nodeURL = "/v1/patients/" + patientId + "/changeproviderrequest/" + providerId;
        dispatch({ type: REQUEST_SENT });
        post(nodeURL)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE })
                dispatch(getPatientDetail(patientId))
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getPatientDetail(patientId) {
    return (dispatch) => {
        return new Promise((resole,reject) => {
            let nodeURL = "/v1/patients/" + patientId;
            get(nodeURL)
                .then((result) => {
                    dispatch({ type: PATIENT_DETAIL_RECEIVED, payload: result })
                    resole(result);
                })
                .catch(error => {
                    dispatch({ type: REQUEST_FAIL });
                    reject(error);
                });
        })
       
    }
}

export function getLastVisitDate(patientId) {
    return (dispatch) => {
        return new Promise((resole,reject) => {
            let nodeURL = `/v1/rincon/patients/${patientId}/getlastVisitDate`;
            get(nodeURL)
                .then((result) => {
                    dispatch({ type: PATIENT_LAST_VISITED_DATE_RECEIVED, payload: result })
                    resole(result);
                })
                .catch(error => {
                    dispatch({ type: REQUEST_FAIL });
                    reject(error);
                });
        })
       
    }
}

export const getLastPasswordReset = (patientId) => {
    return (dispatch) => {
        let endpoint = '/v1/patients/' + patientId + '/lastPasswordUpdate';
        get(endpoint)
            .then((result) => {
                dispatch({ type: RECEIVED_LAST_PASSWORD_RESET, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getPatientRecords(patientId) {
    return (dispatch) => {
        let nodeURL = "/v1/records/" + patientId + '?done=false';
        get(nodeURL)
            .then((result) => {
                dispatch({ type: RECORDS_RECEIVED, payload: result })
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
            });
    }
}

export function getRecord(recordId, callback) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });

        let endpoint = '/v1/record/' + recordId;
        get(endpoint).
            then(result => {
                dispatch({
                    type: GET_RECORD_SUCCESS,
                    payload: result.link
                });
                dispatch({ type: REQUEST_COMPLETE });

                if (callback) {
                    callback();
                }
            }).
            catch(error => {
                dispatch({ type: REQUEST_COMPLETE });

                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Couldn\'t Fetch Record PDF'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);

                if (callback) {
                    callback(true, error);
                }

                console.log('Error while fetching PHS PDF - ', error);
            });
    }
}

export function completeRecord(recordId, patientId) {
    return (dispatch) => {
        let nodeURL = "/v1/record/" + recordId;
        dispatch({ type: REQUEST_SENT });
        post(nodeURL)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(getPatientRecords(patientId));
                dispatch(showToast({
                    status: 'success',
                    title: 'Request Completed',
                    message: 'Request successfully marked as complete'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Request Failed',
                    message: 'Couldn\'t Fetch PHS PDF'

                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function unDeclinePatient(patientId) {
    return (dispatch) => {
        let nodeURL = "/v1/patients/" + patientId + "/unverify";
        dispatch({ type: REQUEST_SENT });
        return put(nodeURL)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'success',
                    title: 'Un-Decline Completed',
                    message: 'Patient successfully un-declined'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Un-Decline Failed',
                    message: 'Couldn\'t un-decline patient'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function optOutPatient(patientId, opt_out_id, optout_reason_id, optout_note) {
    return (dispatch) => {
        let nodeURL = "/v1/patients/" + patientId + "/optout";
        dispatch({ type: REQUEST_SENT });
        let body = {
            status: opt_out_id
        };
        if (opt_out_id === 103 | opt_out_id === 203 || opt_out_id === 206) {
            body = Object.assign({}, body, {
                optout_reason_id: optout_reason_id,
                optout_note: optout_note
            });
        }
        return post(nodeURL, body)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'success',
                    title: 'Opt Out Completed',
                    message: 'Patient successfully opted out'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Opt Out Failed',
                    message: 'Couldn\'t opt out patient'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function deleteInboundRecord(id) {
    return (dispatch) => {
        deleteRequest(`/v1/inbound/records/${id}`)
            .then((response) => {
                dispatch({ type: DELETE_INBOUND_RECORD_SUCCESS, payload: response });
            })
            .catch(() => {
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message: 'This document could not be deleted'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function getUrgentTodoTypes() {
    return (dispatch,getState) => {
        const authData = getState().auth;
        const isUserAdmin = isAdmin(authData.roles);
        const isTriageUser = isTriage(authData.roles);
        let endpoint = `/v1/urgenttodotypes`;
        dispatch({ type: REQUEST_SENT });
        return get(endpoint)
            .then((result) => {
                let options = result;
                if(!isUserAdmin && !isTriageUser){
                    options = _.filter(result,(data) => {
                        return data.id != 24;
                    })
                }
                dispatch({ type: URGENT_TODO_TYPES_RECEIVED, payload: options })
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch urgent todo types'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function getUtilization(id) {
    return (dispatch) => {
        const endpoint = `/v1/mas/${id}/utilization`;
        dispatch({ type: REQUEST_SENT });
        return get(endpoint)
            .then((result) => {
                dispatch({ type: UTILIZATION_RECEIVED, payload: result });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch utilization data'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function getWhiteboard(id, trigger_open) {
    return (dispatch) => {
        const endpoint = `/v1/ccmusers/${id}/whiteboard/note`;
        dispatch({ type: REQUEST_SENT });
        return get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: WHITEBOARD_CONTENT, payload: result });
                if (trigger_open && result.note.trim().length) {
                    dispatch({ type: OPEN_WHITEBOARD })
                    dispatch(showToast({
                        status: 'success',
                        title: 'Whiteboard',
                        message: 'You currently have content on your whiteboard'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch whiteboard content'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function changeWhiteboard(value) {
    return (dispatch) => {
        dispatch({ type: UPDATE_WHITEBOARD_CONTENT, payload: value })
    }
}

export function checkWhiteboard(id) {
    return (dispatch) => {
        const endpoint = `/v1/ccmusers/${id}/whiteboard/note/exist`;
        dispatch({ type: REQUEST_SENT });
        return get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: WHITEBOARD_EXISTS, payload: result });
                if (result == true) {
                    dispatch(getWhiteboard(id, true))
                }
            })
            .catch(error => {
                if (error.code !== 'session_timeout') {
                    console.log("error:", error)
                    dispatch({ type: REQUEST_COMPLETE });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Failure',
                        message: 'Failed to check whiteboard existence'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            });
    }
}

export function createWhiteboard(ccmuser_id, note) {
    return (dispatch) => {
        const endpoint = `/v1/ccmusers/${ccmuser_id}/whiteboard/note`;
        dispatch({ type: REQUEST_SENT });
        return post(endpoint, { ccmuser_id, note })
            .then((result) => {
                dispatch({ type: WHITEBOARD_CONTENT, payload: result });
                dispatch({ type: WHITEBOARD_EXISTS, payload: true });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to save whiteboard update'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function updateWhiteboard(ccmuser_id, note, id) {
    return (dispatch) => {
        const endpoint = `/v1/ccmusers/${ccmuser_id}/whiteboard/note/${id}`;
        dispatch({ type: REQUEST_SENT });
        return put(endpoint, { ccmuser_id, note, id })
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: WHITEBOARD_CONTENT, payload: result });
            })
            .catch(error => {
                if (error.code !== 'session_timeout') {
                    dispatch({ type: REQUEST_COMPLETE });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Failure',
                        message: 'Failed to check whiteboard existence'
                    }));
                    let interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            });
    }
}

export function getCnePerformance(ccmuser_id) {
    return (dispatch) => {
        const endpoint = `/v1/mas/${ccmuser_id}/cneperformance`
        return get(endpoint)
            .then((result) => {
                dispatch({ type: CNE_PERFORMANCE_RECEIVED, payload: result });
            })
            .catch(error => {
                if (error.code !== 'session_timeout') {
                    dispatch({ type: REQUEST_COMPLETE });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Failure',
                        message: 'Failed to check whiteboard existence'
                    }));
                    const interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            });
    }
}

export function getRPMPerformance(ccmuser_id) {
    return (dispatch) => {
        const endpoint = `/v1/mas/${ccmuser_id}/rpmperformance`
        return get(endpoint)
            .then((result) => {
                dispatch({ type: CNE_PERFORMANCE_RECEIVED, payload: result });
            })
            .catch(error => {
                if (error.code !== 'session_timeout') {
                    dispatch({ type: REQUEST_COMPLETE });
                    dispatch(showToast({
                        status: 'error',
                        title: 'Failure',
                        message: 'Failed to get rpm performance'
                    }));
                    const interval = setInterval(() => {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);
                }
            });
    }
}

export const getWorkSummary = (patientId) => {
    return (dispatch) => {
        const endpoint = `/v1/patients/${patientId}/closechart/items`;
        return get(endpoint)
            .then((result) => {
                dispatch({ type: WORK_SUMMARY_RECEIVED, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to retrieve summary of work'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export const clearWorkSummary = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_WORK_SUMMARY });
    }
}

export const getUnenrollReasons = () => {
    return (dispatch) => {
        const endpoint = '/v1/unenrolls/reasons';
        return get(endpoint)
            .then((result) => {
                dispatch({ type: RECEIVED_UNENROLL_REASONS, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to retrieve unenroll reasons',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
};

export const getPatientServicesReasons = () => {
    return (dispatch) => {
        const endpoint = '/v1/support/reasons';
        return get(endpoint)
            .then((result) => {
                dispatch({ type: RECEIVED_PATIENT_SERVICES_REASONS, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to retrieve send to Patient Services reasons',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    }
};

export const requestChartClosure = (openChart) => {
    return (dispatch) => {
        const endpoint = `/v1/supervisors/${openChart.ma_id}/requestclosechart`;
        return post(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_CHART_CLOSURE, payload: result });
                dispatch(openEventStream(openChart.active_patient_id, 'chart_closure_requested'));
                dispatch(initiateChartClosure(openChart));
            })
            .catch((error) => {
                dispatch({ type: REQUEST_CHART_CLOSURE, payload: { id: openChart.active_patient_id } });
                dispatch(openEventStream(openChart.active_patient_id, 'chart_closure_requested'));
                dispatch(initiateChartClosure(openChart));
            });
    }
};

export const openEventStream = (patient_id, event_type) => {
    return (dispatch) => {
        switch (event_type) {
            // Once a request to close a chart has been sent, this listener is generated in order to respond once the chart is actually closed
            case 'chart_closure_requested':
                clearInterval(window.pollingInterval);
                window['pollingInterval'] = setInterval(() => {
                    dispatch(checkIfChartClosed(patient_id));
                }, 15000);
                break;
            // Once a user opens a chart, this listener is generated in order to respond to requests that the chart be closed
            case 'close_chart_requests':
                clearInterval(window.pollingInterval);
                window['pollingInterval'] = setInterval(() => {
                    dispatch(checkForChartClosureRequests(patient_id));
                }, 5000);
                break;
        }
    }
};

export const removeRequestForChartClosure = () => {
    return (dispatch) => {
        dispatch({ type: REMOVE_CHART_CLOSURE_REQUEST });
    };
};

const initiateChartClosure = (openChart) => {
    return (dispatch) => {
        window['autoClosureTimer'] = setInterval(() => {
            clearInterval(window['autoClosureTimer']);
            clearInterval(window.pollingInterval);
            const note = 'Auto-generated chart closure';
            dispatch(closeChartByAdmin(openChart.ma_id, note, openChart.active_patient_id))
        }, 65000);
    };
};

export const checkIfChartClosed = (patient_id) => {
    return (dispatch) => {
        // Endpoint needs to be correct once available
        const endpoint = `/v1/supervisors/ischartopen/${patient_id}`;
        return get(endpoint)
            .then((result) => {
                if (!result.chart_open) {
                    clearInterval(window.pollingInterval);
                    dispatch({ type: REQUEST_CHART_CLOSURE_COMPLETED, payload: result });
                    const secondaryEndpoint = `/v1/patients/${patient_id}`;
                    get(secondaryEndpoint)
                        .then((patient_data) => {
                            clearInterval(window['autoClosureTimer']);
                            dispatch(loadPatientDetail(patient_data));
                        })
                        .catch((error) => {
                            dispatch({ type: REQUEST_COMPLETE });
                            dispatch(showToast({
                                status: 'error',
                                title: 'Failure',
                                message: 'Failed to get patient data',
                            }));
                            const interval = setInterval(() => {
                                clearInterval(interval);
                                dispatch(hideToast());
                            }, 2);
                        });
                }
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to poll for chart closure',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    };
};

const checkForChartClosureRequests = (patient_id) => {
    return (dispatch, getState) => {
        const endpoint = `/v1/patients/${patient_id}/closerequest`;
        return get(endpoint)
            .then((result) => {
                if (result.request_to_close === true) {
                    clearInterval(window.pollingInterval);
                    window['secondaryPollingInterval'] = setInterval(() => {
                        dispatch(checkForRerouting(patient_id));
                    }, 5000);
                    dispatch({ type: CHART_CLOSURE_REQUEST_FOUND, payload: result });
                }
            })
            .catch((error) => {
                clearInterval(window.pollingInterval);
                clearInterval(window.secondaryPollingInterval);
                const stored_queue = window.localStorage.getItem("ccm_queue");
                const queue_user = window.localStorage.getItem("ccm_queue_user");
                if (stored_queue && queue_user === getState().admin.activeUser.username) {
                    dispatch(pushState(null, `/home/${stored_queue}`));
                } else {
                    dispatch(pushState(null, `/home/${getState().queue.currentQueue}`));
                }
                dispatch({ type: CHART_CLOSED_SUCCESSFULLY });
                dispatch(showToast({
                    status: 'error',
                    title: 'Redirected',
                    message: 'Chart was automatically closed',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    };
};

const checkForRerouting = (patient_id) => {
    return (dispatch, getState) => {
        const endpoint = `/v1/patients/${patient_id}/closerequest`;
        get(endpoint)
            .then((result) => {
                // Do nothing
            })
            .catch((error) => {
                parseJSON(error).then((errorObj) => {
                    const exit_chart = errorObj.message === 'User does not have this patients chart open';
                    if (exit_chart) {
                        clearInterval(window.pollingInterval);
                        clearInterval(window.secondaryPollingInterval);
                        const stored_queue = window.localStorage.getItem("ccm_queue");
                        const queue_user = window.localStorage.getItem("ccm_queue_user");
                        if (stored_queue && queue_user === getState().admin.activeUser.username) {
                            dispatch(pushState(null, `/home/${stored_queue}`));
                        } else {
                            dispatch(pushState(null, `/home/${getState().queue.currentQueue}`));
                        }
                        dispatch({ type: CHART_CLOSED_SUCCESSFULLY });
                        dispatch(showToast({
                            status: 'error',
                            title: 'Redirected',
                            message: 'Chart was automatically closed',
                        }));
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            dispatch(hideToast());
                        }, 2);
                    } else {
                        dispatch(showToast({
                            status: 'error',
                            title: 'Failure',
                            message: errorObj.message,
                        }));
                    }
                });
            });
    };
};

export const supportToActive = (patient_id) => {
    return (dispatch) => new Promise((resolve, reject) => {
        const endpoint = `/v1/patients/${patient_id}/unsupport`;
        dispatch({ type: REQUEST_SENT });
        return put(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'success',
                    title: 'Success',
                    message: 'Patient status changed to Active'

                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                resolve(true)
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Patient status change failed',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    })
};

export const supportToPendingUnenroll = (patient_id, notes, reason) => {
    return (dispatch) => new Promise((resolve, reject) => {
        const data = { "notes": notes, unenroll_reason_id: reason },
            nodeURL = `/v1/patients/${patient_id}/unenrollrequest`;
        dispatch({ type: REQUEST_SENT });
        post(nodeURL, data)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'success',
                    title: 'Success',
                    message: 'Patient status changed to Pending Unenroll'

                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                resolve(true)
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Patient status change failed',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    })
};

export const sendAdditionalInformationEmail = (patient_id, phone_number) => {
    return (dispatch) => {
        const endpoint = `/v1/patients/${patient_id}/sendbenefitsmail`;
        const body = { "specialist_phone": phone_number };
        dispatch({ type: REQUEST_SENT });
        post(endpoint, body)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'success',
                    title: 'Success',
                    message: 'Additional information sent to patient'

                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to send additional information to patient',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    };
};

export const getPatientSurveyQuestion = (workitem_id) => {
    return (dispatch) => {
        const endpoint = `/v1/workitems/${workitem_id}/satisfactionsurvey/question`;
        dispatch({ type: REQUEST_SENT });
        get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: RECEIVED_PATIENT_SURVEY_QUESTION, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch survey data',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
};

export const getPatientSurveySubmission = (workitem_id) => {
    return (dispatch) => {
        const endpoint = `/v1/workitems/${workitem_id}/satisfactionsurvey/submissioncheck`;
        dispatch({ type: REQUEST_SENT });
        get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: RECEIVED_PATIENT_SURVEY_SUBMISSTION, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch survey data',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
};

export const postPatientSurvey = (workitem_id, score, notes, question_id) => {
    return (dispatch) => new Promise((resolve, reject) => {
        const endpoint = `/v1/workitems/${workitem_id}/satisfactionsurvey`;
        const body = {
            "satisfaction_question_id": question_id,
            "score": score,
            "notes": notes,
        };
        dispatch({ type: REQUEST_SENT });
        return post(endpoint, body)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'success',
                    title: 'Success',
                    message: 'Survey successfully submitted'

                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                resolve(true)
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Survey failed to submit',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
    })
};

export const postPatientSatisfactionPreferences = (patient_id, preference) => {
    return (dispatch) => {
        const endpoint = `/v1/patients/${patient_id}/toggleshownpsflag`;
        const body = { "show_nps": preference };
        dispatch({ type: REQUEST_SENT });
        put(endpoint, body)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: UPDATE_PATIENT_SATISFACTION_PREFERENCES, payload: preference });
                dispatch(showToast({
                    status: 'success',
                    title: 'Success',
                    message: 'Additional information sent to patient'

                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to send additional information to patient',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    };
};

export const getOrganizationNote = (org_id, patient_id, user_id) => {
    return (dispatch, getState) => {
        const endpoint = `/v1/organizations/${org_id}/note`;
        dispatch({ type: REQUEST_SENT });
        get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: RECEIVED_ORGANIZATION_NOTE, payload: result });
            })
            .catch((error) => {
                const current_state = getState();
                dispatch(closeChart(current_state.app.selectedPatient.id, 'Chart access denied. Could not get organization notes.', 9999, current_state.app.selectedQueue, current_state.admin.activeUser.id));
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch organization note. Please contact IT to access chart.',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
};

export const getAssociatedCharts = (patient_id) => {
    return (dispatch) => {
        const endpoint = `/v1/patients/${patient_id}/associatedpatients`;
        dispatch({ type: REQUEST_SENT });
        get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({ type: RECEIVED_ASSOCIATED_CHARTS, payload: _.sortBy(result, 'status').reverse() });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch associated charts.',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
};

export const resetAssociatedCharts = () => {
    return (dispatch) => {
        dispatch({ type: RECEIVED_ASSOCIATED_CHARTS, payload: [] });
    }
};

export const checkOpenAssociatedChart = (patient_id) => {
    return (dispatch) => new Promise((resolve, reject) => {
        const endpoint = `/v1/patients/${patient_id}/associatedpatients`;
        dispatch({ type: REQUEST_SENT });
        get(endpoint)
            .then((result) => {
                dispatch({ type: REQUEST_COMPLETE });
                resolve({ result })
            })
            .catch((error) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to check associated charts.',
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    })
};

export const getInboundNextMedicalRecord = (queueRecord,callerId) => dispatch => {
    let curentRecordId = 0;
    if (queueRecord) {
        curentRecordId = queueRecord.id;
    }
    let nodeURL = `/v1/inbound/records/next/${curentRecordId}`;
    if(callerId){
        nodeURL = `/v1/inbound/records/next/${curentRecordId}?callerId=${callerId}`;
    }
    dispatch({ type: REQUEST_SENT });
    post(nodeURL)
        .then((result) => {
            dispatch({ type: FAX_QUEUE_RECORD_RECEIVED, payload: result });
            dispatch({ type: REQUEST_COMPLETE });
        })
        .catch(error => {
            // console.log(error);
            dispatch({ type: REQUEST_FAIL });
        });
}

export const getNextFaxRecordForApproval = (queueRecord) => dispatch => {
    let curentRecordId = 0;
    if (queueRecord) {
        curentRecordId = queueRecord.id;
    }
    const nodeURL = `/v1/inbound/records/matches/next/${curentRecordId}`;
    dispatch({ type: REQUEST_SENT });
    post(nodeURL)
        .then((result) => {
            dispatch({ type: FAX_APPROVAL_QUEUE_RECORD_RECEIVED, payload: result });
            dispatch({ type: REQUEST_COMPLETE });
        })
        .catch(error => {
            // console.log(error);
            dispatch({ type: REQUEST_FAIL });
        });
}

export const openRecordFile = (queueRecord) => dispatch => {
    let curentRecordId = 0;
    if (queueRecord) {
        curentRecordId = queueRecord.id;
    }
    const nodeURL = `/v1/inbound/records/${curentRecordId}/link`;
    dispatch({ type: REQUEST_SENT });
    get(nodeURL)
        .then((result) => {
            if (result.exists) {
                window.open(result.link, '_blank').focus();
            } else {
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message: 'Unable to fetch document'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            }
            dispatch({ type: REQUEST_COMPLETE });
        })
        .catch(error => {
            // console.log(error);
            dispatch({ type: REQUEST_FAIL });
        });
}


export const fixRecord = (queueRecord) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    put(`/v1/inbound/records/${queueRecord.id}/movetofix`)
        .then((response) => {
            dispatch({ type: RECORD_FIXED_SUCCESSFULLY, payload: response });
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(getInboundNextMedicalRecord(queueRecord));
        })
        .catch(() => {
            dispatch(showToast({
                status: 'error',
                title: 'Error',
                message: 'Unable to fix record'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch({ type: FAX_QUEUE_RECORD_RECEIVED, payload: {} });
            dispatch({ type: REQUEST_COMPLETE });
        });
}

export const matchRecordV2 = (data, patientId, queueRecord) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    put(`/v2/patients/${patientId}/matchrecord`, data)
        .then((response) => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(getInboundNextMedicalRecord(queueRecord));
        })
        .catch(() => {
            dispatch(showToast({
                status: 'error',
                title: 'Error',
                message: 'Unable to match record'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch({ type: FAX_QUEUE_RECORD_RECEIVED, payload: {} });
            dispatch({ type: REQUEST_COMPLETE });
        });
}

export const archiveRecord = (queueRecord) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    deleteRequest(`/v2/inbound/records/${queueRecord.id}`)
        .then((response) => {
            dispatch({ type: RECORD_ARCHIVE_SUCCESSFULLY, payload: response });
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(getInboundNextMedicalRecord(queueRecord));
        })
        .catch(() => {
            dispatch(showToast({
                status: 'error',
                title: 'Error',
                message: 'This document could not be deleted'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch({ type: FAX_QUEUE_RECORD_RECEIVED, payload: {} });
            dispatch({ type: REQUEST_COMPLETE });
        });

}

export const getUnmatchedData = (data) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    dispatch({ type: RESET_UNMATCHED_DATA });

    let endpoint = '/v1/patients/' + data.id + '/sentmatchedrecordrequests';
    get(endpoint)
        .then((result) => {
            const unmatchedData = _.filter(result, (data) => {
                return data.status == 'SENT';
            });
            const previouslyMatched = _.filter(result, (data) => {
                return data.status == 'MATCHED';
            });
            let payload = {
                unmatched: unmatchedData,
                previouslyMatched: previouslyMatched,
                selectedRowData: data
            };
            dispatch({ type: RECORD_UMMATCHED_DATA_RECEIVED, payload });
            dispatch({ type: REQUEST_COMPLETE });
        })
        .catch((error) => {
            dispatch({ type: REQUEST_FAIL });
        });

}

export const getFaxMatchingPatientList = (lastName, dob, phone) => dispatch => {
    const nameQ = lastName ? `queryLastname=${lastName}` : '';
    const dobQ = dob ? `queryDob=${dob}` : '';
    const phoneQ = phone ? `queryPhone=${phone}` : '';
    const amp = lastName && dob ? '&' : '';
    const amp2 = phone && (lastName || dob) ? '&' : '';
    dispatch({ type: REQUEST_SENT });
    get(`/v1/patients/search?${nameQ}${amp}${dobQ}${amp2}${phoneQ}`)
        .then((response) => {
            dispatch({
                type: FAX_MATCHING_PATIENT_LIST_RECEIVED,
                payload: response,
                searchTerm: lastName,
                searchDate: dob,
            });
            dispatch({ type: REQUEST_COMPLETE });
        })
        .catch(() => {
            dispatch({
                type: SEARCH_PATIENTS_FAILURE,
                searchTerm: lastName,
                searchDate: dob,
            });
            dispatch({ type: REQUEST_COMPLETE });
        });
}
// export function getFaxMatchingPatientList() {
//     return (dispatch) => new Promise((resolve, reject) => {
//         const nodeURL = "/v1/inbound/records";
//         dispatch({type: REQUEST_SENT});
//         // get(nodeURL)
//         //     .then((result)=> {
//         //         dispatch({type: FAX_MATCHING_PATIENT_LIST_RECEIVED, payload: result})
//         //         resolve(true)
//         //     })
//         //     .catch(error => {
//         //         console.log(error);
//         //         dispatch({type: REQUEST_FAIL});
//         //     });
//         setTimeout(()=>{
//             dispatch({type: FAX_MATCHING_PATIENT_LIST_RECEIVED, payload: [
//                     {
//                         name:'Yogesh Patel',
//                         date:'12/12/2018',
//                         contactNumber:'9960614174',
//                         providerName:'Gerge Test123',
//                         status:'Unenroll'
//                     },
//                     {
//                         name:'Yogesh Patel2',
//                         date:'12/12/2018',
//                         contactNumber:'9960614174',
//                         providerName:'Gerge Test123',
//                         status:'Unenroll'
//                     },
//                     {
//                         name:'Yogesh Patel',
//                         date:'12/12/2018',
//                         contactNumber:'9960614174',
//                         providerName:'Gerge Test123',
//                         status:'Unenroll'
//                     },
//                     {
//                         name:'Yogesh Patel2',
//                         date:'12/12/2018',
//                         contactNumber:'9960614174',
//                         providerName:'Gerge Test123',
//                         status:'Unenroll'
//                     }
//                 ]});
//             dispatch({type: REQUEST_COMPLETE});
//         },500);
//     })
// }

export const resetUnmatchData = () => {
    return { type: RESET_UNMATCHED_DATA };
}

export const getChangeProviderRequest = (patientId, callback) => dispatch => {
    //http://localhost:8443/ccm/v1/
    dispatch({ type: REQUEST_SENT });
    get(`/v1/patients/${patientId}/changeproviderrequest`)
        .then((response) => {
            callback(response);
            dispatch({ type: REQUEST_COMPLETE });
        })
        .catch(() => {
            dispatch(showToast({
                status: 'error',
                title: 'Error',
                message: 'Error to fetch patient detail'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch({ type: REQUEST_COMPLETE });
        });
}

export const updateContactPreference = (patient, contactPreference,preferred_language, callback) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    put(`/v1/patients/${patient.id}/contactpreferences`, {
        contact_preferences: contactPreference,
        preferred_language
    }).then((response) => {
        callback(response);
        dispatch(showToast({
            status: 'success',
            title: 'Success',
            message: 'Successfully updated contact preference'
        }));
        const interval = setInterval(() => {
            clearInterval(interval);
            dispatch(hideToast());
        }, 2);
        dispatch({ type: REQUEST_COMPLETE });
        dispatch(getPatientDetail(patient.id));
    }).catch(() => {
        dispatch(showToast({
            status: 'error',
            title: 'Error',
            message: 'Error to update contact preference'
        }));
        const interval = setInterval(() => {
            clearInterval(interval);
            dispatch(hideToast());
        }, 2);
        dispatch({ type: REQUEST_COMPLETE });
    });
}

export const updateCallSchedulingPreference = (patient, schedulable, callback) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    put(`/v1/patients/${patient.id}/callscheduablepreference`, {
        schedulable: schedulable == 'No'
    }).then((response) => {
        callback(response);
        dispatch(showToast({
            status: 'success',
            title: 'Success',
            message: 'Successfully updated contact preference'
        }));
        const interval = setInterval(() => {
            clearInterval(interval);
            dispatch(hideToast());
        }, 2);
        dispatch({ type: REQUEST_COMPLETE });
        dispatch(getPatientDetail(patient.id));
    }).catch(() => {
        dispatch(showToast({
            status: 'error',
            title: 'Error',
            message: 'Error to update contact preference'
        }));
        const interval = setInterval(() => {
            clearInterval(interval);
            dispatch(hideToast());
        }, 2);
        dispatch({ type: REQUEST_COMPLETE });
    });
}
export const getInboundRecordsToFix = (pageNum = 0,
    pageSize = 20) => dispatch => {
        dispatch({ type: REQUEST_SENT });
        const headers = {
            'X-Page': pageNum,
            'X-Page-Size': pageSize,
        };
        get(`/v1/inbound/records/tofixqueue`, headers)
            .then((response) => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch({
                    type: INBOUND_RECORDS_RECEIVED_TO_FIX_QUEUE,
                    payload: response
                });
            })
            .catch((error) => {
                // console.log(error);
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message: 'Error to fetch inbound records to fix'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                dispatch({ type: REQUEST_COMPLETE });
            });
    }

export const completeToFixRecord = (recordId, notes) => dispatch => {
    dispatch({ type: REQUEST_SENT });
    put(`/v1/inbound/records/${recordId}/completetofixrecord`, {
        note: notes
    })
        .then((response) => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(getInboundRecordsToFix());
            dispatch(showToast({
                status: 'success',
                title: 'Success',
                message: 'Successfully Complete the Fix Record'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        })
        .catch((error) => {
            console.log(error);
            dispatch(showToast({
                status: 'error',
                title: 'Error',
                message: 'Error to complete the fix record'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            dispatch({ type: REQUEST_COMPLETE });
        });
}

export function getSocialDeterminants() {
    return (dispatch) => {
        let endpoint = `/v1/valuesocialdeterminants`;
        dispatch({ type: REQUEST_SENT });

        return get(endpoint)
            .then((result) => {
                dispatch({ type: TODO_SOCIAL_DETERMINANTS_RECEIVED, payload: result })
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch social determinants'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function getOptOutReasons() {
    return (dispatch) => {
        let endpoint = `/v1/optoutreasons`;
        dispatch({ type: REQUEST_SENT });

        return get(endpoint)
            .then((result) => {
                dispatch({ type: OPT_OUT_REASONS_RECEIVED, payload: result })
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch optout reasons'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function resetSearchResult() {
    return (dispatch) => {
        dispatch({ type: RESET_SEARCH_RESULE });
    }
}
export const getChartspanProviders = (patientId) => dispatch => {
    const endpoint = `/v1/patients/${patientId}/chartspanproviders`;
    dispatch({ type: REQUEST_SENT });
    return get(endpoint)
        .then((result) => {
            dispatch({
                type: ON_PATIENT_CHARTSPAN_PROVIDERS_RECEIVED,
                payload: result
            })
        }).catch(error => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch Chartspan Providers'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        });
}

export const getPharmacyOptions = (patientId) => dispatch => {
    const endpoint = `/v1/patients/${patientId}/pharmacies`;
    dispatch({ type: REQUEST_SENT });
    return get(endpoint)
        .then((result) => {
            dispatch({
                type: ON_PATIENT_PHARMACY_OPTIONS_RECEIVED,
                payload: result
            })
        }).catch(error => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch pharmacy options'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        });
}

export const getSlots = (date, timezone) => dispatch => {

    return new Promise((resolve, reject) => {
        const endpoint = `/v1/callschedules`;
        const fixedData = [
            {
              label:'09:00 am'
            },
            {
                label:'09:30 am'
            },
            {
                label:'10:00 am'
            },
            {
                label:'10:30 am'
            },
            {
                label:'11:00 am'
            },
            {
                label:'11:30 am'
            },
            {
                label:'12:00 pm'
            },
            {
                label:'12:30 pm'
            },
            {
                label:'01:00 pm'
            },
            {
                label:'01:30 pm'
            },
            {
                label:'02:00 pm'
            },
            {
                label:'02:30 pm'
            },
            {
                label:'03:00 pm'
            },
            {
                label:'03:30 pm'
            },
            {
                label:'04:00 pm'
            },
            {
                label:'04:30 pm'
            },
            {
                label:'05:00 pm'
            },
            {
                label:'05:30 pm'
            },
            {
                label:'06:00 pm'
            },
            {
                label:'06:30 pm'
            }];
        dispatch({ type: REQUEST_SENT });
        post(endpoint, {
            date,
            timezone
        })
            .then((result) => {
                const resultList = [];
                _.each(fixedData,(data) => {
                    const slotLabel = data.label;
                    const filteredData = _.filter(result,(slotData)=>{
                        const slot = moment(slotData.start_time,'HH:mm:ss').format('hh:mm a');
                        return slot == slotLabel;
                    })
                    if(filteredData.length > 0){
                        const slotData ={...filteredData[0],slotLabel:slotLabel}
                        resultList.push(slotData)
                    }else{
                        resultList.push({slotLabel:slotLabel})
                    }

                })
                const dataList = [];
                for (let i = 0; i < resultList.length; i = i + 2) {
                    const data1 = resultList[i];
                    const data2 = resultList[i + 1];
                    dataList.push([data1, data2]);
                }
                resolve(dataList);
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_COMPLETE });
                dispatch(showToast({
                    status: 'error',
                    title: 'Failure',
                    message: 'Failed to fetch call schedules'
                }));
                let interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    })
}

export const saveScheduleNextCall = (patientId, slotId) => dispatch => {
    const endpoint = `/v1/patients/${patientId}/slots/${slotId}/schedulecall`;
    put(endpoint, {})
        .then((result) => {
            console.log("result:", result);
            dispatch(getPatientDetail(patientId))
        })
        .catch(error => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch call schedules'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        });
}

export const getCarePlanTypes = () => dispatch => {
    const endpoint = `/v1/gaps/careplantypes`;

    get(endpoint)
        .then((result) => {
            dispatch({
                type:CARE_PLAN_TYPES,
                payload:result
            })
        })
        .catch(error => {
            dispatch({type: REQUEST_COMPLETE});
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch careplan types'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        })
}

export const getCarePlanQuestions = (carePlanID) => dispatch => {
    const endpoint = `/v1/dynamic/${carePlanID}`
    get(endpoint)
        .then((result) => {
            dispatch({
                type:CARE_PLAN_QUESTION_RECEIVED,
                payload:result
            })
        })
        .catch(error => {
            dispatch({type: REQUEST_COMPLETE});
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch questions'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        })
}
export const getCarePlans = (patientId) => dispatch => {
    const endpoint = `/v1/gaps/patient/${patientId}/careplans`
    get(endpoint)
        .then((result) => {
            dispatch({
                type:PATIENT_CARE_PLAN_RECEIVED,
                payload:result
            })
            dispatch({type: REQUEST_COMPLETE});
        })
        .catch(error => {
            dispatch({type: REQUEST_COMPLETE});
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch careplan types'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        })
}
export const createCarePlan = (patientId,carePlanTypeId) => dispatch => {

    post(`/v1/gaps/patient/${patientId}/careplan`,{
        "gaps_care_plan_type_id": carePlanTypeId
    })
        .then(result => {
            dispatch(getCarePlans(patientId))
            dispatch(showToast({
                status: 'success',
                title: 'Success',
                message: 'Care Plan Created Successfully'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        })
        .catch(error => {
            dispatch({type: REQUEST_COMPLETE});
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to create careplan'
            }));
            const interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        })
}

export function getPatientEducations(patientId) {
    return (disptach) => {
        disptach({ type: REQUEST_SENT });
        const endpointURL = "/v1/patients/" + patientId + "/educations";
        get(endpointURL).
            then((response) => {
                disptach({ type: PATIENT_EDUCATIONS_RECEIVED, payload: response.reverse() });
            }).
            catch(error => {
                console.log(error);
            });
    }
}

export function addPatientEducation(patientId, title, education) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/educations";
        let data = {
            title: title,
            education_desc:education,
            patient_id:patientId
        };
        post(endpoint, data).
            then((response) => {
                dispatch(getPatientEducations(patientId));
            }).
            catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function updatePatientEducation(educationId,patientId, title, education) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/educations/"+educationId;
        let data = {
            title: title,
            education_desc:education,
            patient_id:patientId,
            id:educationId
        };
        put(endpoint, data).
            then((response) => {
                dispatch(getPatientEducations(patientId));
            }).
            catch(error => {
                console.log(error);
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function deletePatientEducation(educationId,patientId) {
    return (dispatch) => {
        dispatch({ type: REQUEST_SENT });
        let endpoint = "/v1/patients/" + patientId + "/educations/"+educationId;

        deleteRequest(endpoint).
        then((response) => {
            dispatch(getPatientEducations(patientId));
        }).
        catch(error => {
            dispatch({ type: REQUEST_FAIL });
        });
    }
}



export function getPatientNotes(patientId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: REQUEST_SENT });
            let endpoint = "/v1/patients/" + patientId + "/notes";
            get(endpoint).
            then((response) => {
                resolve(response);
                disptach({ type: MAKE_CALL_SUCCESS });
                
            }).
            catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
        })
    }
}
export const getMedicationOptions = (patientId) => dispatch => {
    const endpoint = `/v1/patients/${patientId}/medications`;
    dispatch({ type: REQUEST_SENT });
    return get(endpoint)
        .then((result) => {
            dispatch({
                type: ON_PATIENT_MEDICATION_OPTIONS_RECEIVED,
                payload: result
            })
        }).catch(error => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch medication options'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        });
}

export const getGoalReviewFrequency = () => dispatch => {
    const endpoint = `/v1/goalreviewfrequencies`;
    dispatch({ type: REQUEST_SENT });
    return get(endpoint)
        .then((result) => {
            dispatch({
                type: ON_GOAL_REVIEW_FREQUENCY_RECEVIED,
                payload: result
            })
        }).catch(error => {
            dispatch({ type: REQUEST_COMPLETE });
            dispatch(showToast({
                status: 'error',
                title: 'Failure',
                message: 'Failed to fetch goal review frequency'
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
        });
}

export const getEngagementHistory = (patientId) => {
    return (dispatch) => {
        let endpoint = '/v1/patients/' + patientId + '/engagementhistory';
        get(endpoint)
            .then((result) => {
                dispatch({ type: PATIENT_ENGAGEMENT_HISTIRY_RECEIVED, payload: result });
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export const changeProviderRequestConsent = (patientId) => {
    return (dispatch) => {
        let endpoint = `/v1/patients/${patientId}/changeproviderrequest/consent`;
        dispatch({type: REQUEST_SENT});
        post(endpoint,{})
            .then((result) => {
                dispatch({type: REQUEST_COMPLETE});
                dispatch(getPatientDetail(patientId));
                dispatch(showToast({
                    status: 'success',
                    title: 'Successfully',
                    message: 'Successfully Submitted Consent Request'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message: 'Error Submitted Consent Request'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export const changeProviderRequestDissent = (patientId) => {
    return (dispatch) => {
        let endpoint = `/v1/patients/${patientId}/changeproviderrequest/dissent`;
        dispatch({type: REQUEST_SENT});
        post(endpoint,{})
            .then((result) => {
                dispatch({type: REQUEST_COMPLETE});
                dispatch(getPatientDetail(patientId));
                dispatch(showToast({
                    status: 'success',
                    title: 'Successfully',
                    message: 'Successfully Submitted to Support'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_FAIL });
                dispatch(showToast({
                    status: 'error',
                    title: 'Error',
                    message: 'Error Submitted to Support'
                }));
                const interval = setInterval(() => {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}

export function redirectToSearch(searchParam) {
    return (dispatch) => {
        dispatch(pushState(null, `/home/search?${searchParam}`));
    }
}

export function isChartOpen(patientId) {
    return (dispatch) => {
    return new Promise((resolve,reject) => {
        get(`/v1/patients/${patientId}/ischartopenable`)
            .then((result) => {
                resolve(!result);
            })
            .catch(() => { 
                reject(); 
                return false});
        }
    )
    }
}

export function getGoalTypes() {
    return (dispatch) => {
        let endpoint = "/v1/goal/types";
        get(endpoint)
            .then((result) => {
                dispatch({ type: ON_GOAL_TYPES_RECEIVED, payload: result });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}
export function getGoalSources() {
    return (dispatch) => {
        let endpoint = "/v1/goal/sources";
        get(endpoint)
            .then((result) => {
                dispatch({ type: ON_GOAL_SOURCES_RECEIVED, payload: result });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getGoalCompletionStatuses() {
    return (dispatch) => {
        let endpoint = "/v1/goal/completion-statuses";
        get(endpoint)
            .then((result) => {
                dispatch({ type: ON_GOAL_COMPLETION_STATUS_RECEIVED, payload: result });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function getGoalStepTypes() {
    return (dispatch) => {
        let endpoint = "/v1/goal/step/types";
        get(endpoint)
            .then((result) => {
                dispatch({ type: ON_GOAL_STEP_TYPES_RECEIVED, payload: result });
                dispatch({ type: REQUEST_COMPLETE });
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
}

export function updatePOA(selectedPatientId,poa,callback){

    return (dispatch) => {
        if(!poa){
            dispatch(showToast({
                status: 'error',
                title: "Inavlid POA",
                message: "POA is mandatory field."
            }));
            let interval = setInterval(() => {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            return;
        }else{
            let endpoint = `/v1/patients/${selectedPatientId}/poa`;
            const payload = {
                "poa_name": poa
            }
            
            put(endpoint,payload)
                .then((result) => {
                    dispatch(getPatientDetail(selectedPatientId))
                    dispatch({ type: REQUEST_COMPLETE });
                    if(callback){
                        callback(result);
                    }
                })
                .catch(error => {
                    dispatch({ type: REQUEST_FAIL });
                });
        }
       
    }
    
}

export function deletePOA(selectedPatientId,callback){

    return (dispatch) => {
        let endpoint = `/v1/patients/${selectedPatientId}/poa`;
        
        deleteRequest(endpoint)
            .then((result) => {
                dispatch(getPatientDetail(selectedPatientId))
                dispatch({ type: REQUEST_COMPLETE });
                if(callback){
                    callback(result);
                }
            })
            .catch(error => {
                dispatch({ type: REQUEST_FAIL });
            });
    }
    
}

export function openChartSummary() {
    return dispatch => {
        dispatch({ type: ON_OPEN_CHART_SUMMARY });
    }
}

export function openChartTab(tabKey){
    return dispatch => {
        dispatch({ type: ON_OPEN_CHART_TAB,payload:tabKey });
    }
}

export function closeChartSummary() {
    return dispatch => {
        dispatch({ type: ON_CLOSE_CHART_SUMMARY });
    }
}

export function getTODOOpenMIPS(patientId) {
    return new Promise((resolve,reject) => {
        let endpoint = `/v1/patients/${patientId}/todos/open/mips`;
        get(endpoint)
            .then((result) => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    })
}

export function getCCMAssessmentsSummary(patientId,page='assessments'){
    return new Promise((resolve,reject) => {
        let endpoint = `/v1/dynamic/${page}/patient/${patientId}/summary`;
        get(endpoint)
            .then((result) => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    })
}

export function getOpenGoals(patientId){
    return new Promise((resolve,reject) => {
        let endpoint = `/v1/patients/${patientId}/open_goals`;
        get(endpoint)
            .then((result) => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    })
}


export function getCaseHistory(patientId,days=60) {
    return new Promise((resolve,reject) => {
        let endpoint = "/v1/patients/" + patientId + "/casehistory?history_days="+days;
        get(endpoint).
            then((result) => {
                resolve(result);
            }).
            catch(error => {
                reject(error)
            });
    })
}