/**
 * Created by synerzip on 08/12/15.
 */
let config ={
    //BASE_URL: "https://devapi.chartspan.com:8443/ccm",
    //BASE_URL: "https://demo.chartspan.com:8443/ccm",
    BASE_URL:'https://ccm-api.chartspan.com',
    REPORTS_URL: 'https://reports.chartspan.com/prod/',
    //CHART_WEB_URL:'http://dev-chartspan-com.s3-website-us-west-2.amazonaws.com/',
    //CHART_WEB_URL:'http://localhost:3003/',
    CHART_WEB_URL:'https://app.chartspan.com/',
    //ENV: 'DEV'
    //ENV: 'DEMO'
    ENV: 'PROD',
    CE_OUTBOUND_NUMBER:'855-880-9664',
    CLINICAL_OUTBOUND_NUMBER:'855-242-7877',
};


export default config;
